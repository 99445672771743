<mat-tab-group (selectedTabChange)="tabChanged($event)" mat-align-tabs="end" animationDuration="0ms"
  [dynamicHeight]="true">
  <mat-tab *ngFor="let item of frecuencies" [label]="item">
    <ng-template matTabContent>
      <div [ngSwitch]="item">
        <div *ngSwitchCase="'DIARIO'">
          <div fxLayout="row">
            <div fxFlex></div>
            <mat-form-field>
              <input matInput [matDatepicker]="quotedailydate" placeholder="Elija una fecha"
                [formControl]="fromDailyCtrl" readonly>
              <mat-datepicker-toggle matSuffix [for]="quotedailydate"></mat-datepicker-toggle>
              <mat-datepicker #quotedailydate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div *ngSwitchCase="'MENSUAL'">
          <div fxLayout="row">
            <div fxFlex></div>
            <app-monthpicker (changed)="getQuotesAndValues($event)"></app-monthpicker>
          </div>
        </div>
        <div *ngSwitchCase="'DESDE-HASTA'">
          <div fxLayout="row">
            <div fxFlex></div>
            <app-rangepicker (changed)="getQuotesAndValuesByRange($event)"></app-rangepicker>
          </div>
        </div>
        <div *ngSwitchCase="'ANUAL'">
          <div fxLayout="row">
            <div fxFlex></div>
            <app-yearpicker (changed)="getQuotesAndValues($event)"></app-yearpicker>
          </div>
        </div>
        <div *ngSwitchCase="'TRIMESTRAL'">
          <div fxLayout="row">
            <div fxFlex></div>
            <app-quarterpicker (changed)="getQuotesAndValues($event)"></app-quarterpicker>
          </div>
        </div>
        <div *ngSwitchCase="'FECHA'">
          <div fxLayout="row">
            <div fxFlex></div>
            <mat-form-field>
              <input matInput [matDatepicker]="quotedailydate" placeholder="Elija una fecha"
                [formControl]="fromDailyCtrl" readonly>
              <mat-datepicker-toggle matSuffix [for]="quotedailydate"></mat-datepicker-toggle>
              <mat-datepicker #quotedailydate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div *ngSwitchDefault>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex></div>
        <button (click)="pasteFromClipboard()"  mat-raised-button>Copiar desde portapapeles</button>
      </div>
      <mat-accordion [multi]="true" *ngIf="res && res.categoryGroups">
        <mat-expansion-panel *ngFor="let catGroup of res.categoryGroups" [expanded]="true">
          <mat-expansion-panel-header>
            {{catGroup.category.name}}
          </mat-expansion-panel-header>
          <!-- <div *ngFor="let cQuote of catGroup.quotes" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
            <mat-form-field fxFlex>
              <mat-label>{{cQuote.quote.name}}</mat-label>
              <input type="number" matInput [(ngModel)]="cQuote.actualValue.value" autocomplete="off">
              <mat-hint>{{cQuote.quote.scale}}</mat-hint>
            </mat-form-field>
            <div>
              <div>Último Valor: {{cQuote.lastValue?.value}}</div>
              <div>Fecha: {{cQuote.lastValue?.from | date }} {{cQuote.lastValue?.to | date }}</div>
            </div>

            <mat-icon
              *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value > cQuote.lastValue.value">
              arrow_upward</mat-icon>
            <mat-icon
              *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value < cQuote.lastValue.value">
              arrow_downward</mat-icon>
            <mat-icon
              *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value == cQuote.lastValue.value">
              remove</mat-icon>
            <mat-icon *ngIf="!cQuote.actualValue.value || !cQuote.lastValue.value">priority_hight</mat-icon>
          </div> -->
          <app-quote-input *ngFor="let cQuote of catGroup.quotes" [cQuote]="cQuote" [cQuoteValue]="cQuote.actualValue.value" ></app-quote-input>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
    <!-- <ng-template matTabContent>
      The {{item}} Content
    </ng-template> -->
  </mat-tab>
</mat-tab-group>

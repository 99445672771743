import { Component, OnInit, Input } from '@angular/core';
import { Tip } from '../../models/tip.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TipService } from '../../services/tip.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.scss']
})
export class TipComponent implements OnInit {
  @Input() tip: Tip;
  tipGroup: FormGroup;
  constructor(private fb: FormBuilder,
    private tipService: TipService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.tipGroup = this.fb.group({
      id: [this.tip.id],
      year: [this.tip.year, Validators.required],
      title: [this.tip.title, Validators.required],
      value: [this.tip.value, Validators.required],
      subvalue: [this.tip.subValue, Validators.required],
      description: [this.tip.description, Validators.required]
    });
  }
  createTip() {
    if (this.tipGroup.valid) {
      this.tip = this.tipGroup.value;
      if (this.tip.id) {
        this.tipService.updateTip(this.tip).subscribe(() => {
          this.snackBar.open('Tip guardado.', 'ok', {
            duration: 4000
          });
        });
      } else {
        this.tipService.createTip(this.tip).subscribe(v => {
          this.tip = v; this.snackBar.open('Tip Creado satisfactoriamente.', 'ok', {
            duration: 4000
          });
        });
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LRPlusService } from '../../services/lrplus.service';
import { UserResponse } from '../../models/user-response.model';
import { UserNotification } from '../../models/user-notification.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-result',
  templateUrl: './user-result.component.html',
  styleUrls: ['./user-result.component.scss']
})
export class UserResultComponent implements OnInit {
  loadingModule = true;
  email: string;
  userResponse: UserResponse;
  notifColumns = ['name', 'lastSend', 'allowSend'];
  mailsColumns = ['subject', 'sended'];
  editSubscription: boolean;
  updatingSubscription: boolean;
  newSubscriberId = new FormControl('', [Validators.required]);
  oldSubscriberId: string;
  constructor(private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private lrplusService: LRPlusService) {
    this.email = this.activatedRoute.snapshot.paramMap.get('email');
  }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.lrplusService.searchUser(this.email).subscribe(
      v => {
        if (v) {
          this.userResponse = v;
          this.newSubscriberId.setValue(v.subscriberId);
        }
      },
      err => { },
      () => this.loadingModule = false
    );
  }
  updateNotif(notif: UserNotification) {
    this.lrplusService.updateNotification(notif).subscribe(() => {
      this.snackBar.open('Notificación modificada correctamente.', null, {
        duration: 2000,
      });
    });
  }

  updateUserSubscription() {
    if (this.userResponse.subscriberId != this.newSubscriberId.value) {
      if (this.newSubscriberId.valid && this.newSubscriberId.touched && this.newSubscriberId.value) {
        this.oldSubscriberId = this.userResponse.subscriberId;
        this.userResponse.subscriberId = this.newSubscriberId.value;
        this.editSubscription = false;
        this.updatingSubscription = true;
        this.lrplusService.subscriberAddUser(this.userResponse.email, this.newSubscriberId.value).subscribe(
          v => {
            if (!v.success) {
              this.newSubscriberId.setValue(this.oldSubscriberId);
              this.userResponse.subscriberId = this.oldSubscriberId;
              this.snackBar.open(v.errorDescription, "Cerrar",);
            }
            else {
              this.userResponse.subscriberIsActive = true;
              this.snackBar.open('Suscripción modificada correctamente.', null, {
                duration: 2000,
              });
            }
          },
          err => { },
          () => this.updatingSubscription = false);
      }
    }
    else {
      this.editSubscription = false;
    }
  }
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/custom/custom-error-state-matcher';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TopCompanyService } from 'src/app/modules/top-companies/services/top-company.service';
import { TopCompaniesDataSource } from 'src/app/modules/top-companies/datasources/top-companies-data-source';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, AfterViewInit {
  numberFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('[0-9]*')
  ]);

  matcher = new CustomErrorStateMatcher();
  dataSource: TopCompaniesDataSource;
  displayedColums = ['nit', 'name', 'state', 'actions'];
  companyFilter: FormControl;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private router: Router,
    private topCompanyService: TopCompanyService) {
    this.companyFilter = new FormControl();
    this.companyFilter.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(v => {
        this.loadTopCompanies();
      });
  }

  ngOnInit() {
    this.dataSource = new TopCompaniesDataSource(this.topCompanyService);
    this.dataSource.loadTopCompanies(1, 50);
  }
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadTopCompanies();
    });
    this.paginator.page.pipe(tap(() => this.loadTopCompanies())).subscribe();
  }

  doSearch() {
    const nit = this.numberFormControl.value;
    this.router.navigate(['/10000-empresas/empresas', nit]);
  }

  loadTopCompanies() {
    this.dataSource.loadTopCompanies(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active,
      this.companyFilter.value
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { PqrService } from '../../services/pqr.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-pqr-create',
  templateUrl: './pqr-create.component.html',
  styleUrls: ['./pqr-create.component.scss']
})
export class PqrCreateComponent implements OnInit {

  docTypes = [];
  sources = [];
  submitted = false;
  saving = false;
  id: string;
  addPqrForm = this.fb.group({
    clientTid: [null, Validators.required],
    clientId: [null, Validators.required],
    clientName: [null, Validators.required],
    clientEmail: [null, Validators.email],
    clientMobile: [null],
    source: [null, Validators.required],
    subject: [null, Validators.required],
    description: [null, Validators.required]
  });
  constructor(private pqrService: PqrService,
    private fb: FormBuilder) {
    this.sources = pqrService.sources;
    this.docTypes = pqrService.docTypes;
  }

  ngOnInit() {
  }
  addPqr() {
    if (this.addPqrForm.valid && !this.saving) {
      this.saving = true;
      this.pqrService.addPqr(this.addPqrForm.value).subscribe(q => {
        this.saving = false;
        this.submitted = true;
        this.id = q.id;
      });
    }
  }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';

export const QUARTER_FORMATS = {
  parse: {
    dateInput: 'Q',
  },
  display: {
    dateInput: 'Q [Trimestre] YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-quarterpicker',
  templateUrl: './quarterpicker.component.html',
  styleUrls: ['./quarterpicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: QUARTER_FORMATS },
  ]
})
export class QuarterpickerComponent {
  @Output() changed = new EventEmitter<Moment>();
  quarterCtrl: FormControl;

  constructor() {
    const actualMonth = moment();
    actualMonth.date(1);
    actualMonth.month(parseInt(`${actualMonth.month() / 3}`, 10) * 3);
    this.quarterCtrl = new FormControl(actualMonth);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.quarterCtrl.value;
    ctrlValue.year(normalizedYear.year());
    this.quarterCtrl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.quarterCtrl.value;
    ctrlValue.month(parseInt(`${normalizedMonth.month() / 3}`, 10) * 3);
    ctrlValue.date(1);
    this.quarterCtrl.setValue(ctrlValue);
    this.changed.emit(ctrlValue);
    datepicker.close();
  }

}

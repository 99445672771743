import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  private throwErrorSource = new Subject<string>();
  throwErrorSource$ = this.throwErrorSource.asObservable();
  hostDomain = '';
  constructor() {}
  handleError(error: HttpErrorResponse, description?: string) {
    // if (error.error instanceof ErrorEvent) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   console.error('An error occurred:', error.error.message);
    // } else {
    //   // The backend returned an unsuccessful response code.
    //   // The response body may contain clues as to what went wrong,
    //   console.error(
    //     `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    //   );
    // }
    // return an observable with a user-facing error message
    this.throwErrorSource.next(
      `Error Comunicación: ${description} ' intente de nuevo más tarde o comunique el error a el área de TI.`
    );
    return [];
  }
}

<div class="container">
  <h1>Subir Datos IPC</h1>
  <div>
    <input
      #imgFileInput
      hidden
      type="file"
      name="file"
      (change)="fileChange($event)"
      placeholder="Upload file"
      accept=".xlsx"
    />
    <ngx-file-drop (onFileDrop)="dropFile($event)">
      <ng-template ngx-file-drop-content-tmp>
        <div *ngIf="!file; else fileName">
          <button mat-button (click)="imgFileInput.click()">
            Subir archivo excel
          </button>
          o arrastrar aquí
        </div>
        <ng-template #fileName>
          <div>
            {{ file?.name }}
            <br />
            <button mat-button (click)="imgFileInput.click()">
              Cambiar archivo excel
            </button>
            o arrastrar aquí
          </div></ng-template
        >
      </ng-template>
    </ngx-file-drop>
  </div>
  <div class="controls">
    <div>
      <button *ngIf="!isSaving" mat-raised-button (click)="loadFile()">
        Guardar
      </button>
      <mat-spinner *ngIf="isSaving" [diameter]="30"></mat-spinner>
    </div>
    <button [routerLink]="['../']" mat-raised-button color="warn">
      Cancelar
    </button>
  </div>
  <div>
    <h3>Requisitos para la carga del archivo</h3>
    <ol class="file-rules">
      <li>
        El nombre del archivo debe ser el periodo al que pertenecen los datos,
        en el formato yyyy-MM. Ej: 2024-09.xlsx
      </li>
      <li>
        En la columna de ciudades, el valor <strong>Total IPC</strong>, se debe
        cambiar por <strong>Todas</strong>.
      </li>
      <li>
        El libro debe tener la data en tres hojas con los nombres Mensual, Año
        Corrido y Doce Meses.
      </li>
      <li>
        <a href="https://s.lalr.co/admin/2024-08.xlsx">Acá</a>, puede encontrar
        la plantilla para completar la información. El orden de las filas y las
        columnas es la mismo del archivo que entrega el DANE.
      </li>
    </ol>
  </div>
</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '../services/auth-guard.service';
import { CompanyComponent } from './top-companies/components/company/company.component';
import { IndexComponent } from './top-companies/components/index/index.component';
import { CreateTipComponent } from './top-companies/components/create-tip/create-tip.component';
import { IndexTipsComponent } from './top-companies/components/index-tips/index-tips.component';
import { EditTipComponent } from './top-companies/components/edit-tip/edit-tip.component';


const routes: Routes = [
  { path: '10000-empresas/tips/nuevo', component: CreateTipComponent, canActivate: [AuthGuardService] },
  { path: '10000-empresas/tips/:id', component: EditTipComponent, canActivate: [AuthGuardService] },
  { path: '10000-empresas/tips', component: IndexTipsComponent, canActivate: [AuthGuardService] },
  { path: '10000-empresas/empresas/:id', component: CompanyComponent, canActivate: [AuthGuardService] },
  { path: '10000-empresas/empresas', component: IndexComponent, canActivate: [AuthGuardService] },
  { path: '10000-empresas', redirectTo: '/10000-empresas/empresas', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TopCompaniesRoutingModule { }

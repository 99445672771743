<mat-toolbar class="principal">
  <mat-toolbar-row fxLayout="row">
    <button class="hamburguer" mat-button title="Menú" (click)="sidenav.toggle()">
      <mat-icon aria-label="Menú">menu</mat-icon>
    </button>
    <a routerLink="/">
      <img class="logo" src="//s.lalr.co/lr/images/logo-lr-rojo.svg" alt="Admin LR">
    </a>
    <div fxFlex></div>
    <div *ngIf="userService.user" fxLayout="row" fxLayoutAlign="center center" class="profile-button">
      <button mat-button [matMenuTriggerFor]="closeMenu">
        <img class="image-button" [src]="userService.user.imageSrc" [alt]="userService.user.email">
        <span class="user-name">{{userService.user.name}}</span>
      </button>
      <mat-menu fxFlex #closeMenu="matMenu">
        <a href="/salir" target="_self" fxFlex mat-menu-item>Cerrar Sesión</a>
      </mat-menu>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="principal">
  <mat-sidenav mode="side" #sidenav>
    <div fxLayout="column" class="side-buttons" *ngIf="userService.user">
      <a mat-button routerLinkActive="active mat-primary" [routerLink]="['/inicio']">Inicio</a>
      <a *ngFor="let item of userService.user.menu" [ngClass]="{'sub': item.sub}" mat-button routerLinkActive="active mat-primary" [routerLink]="[item.path]">{{item.name}}</a>
      <!-- <a mat-button routerLinkActive="active mat-primary" [routerLink]="['/10000-empresas']">10000 Empresas</a>
      <a class="sub" mat-button routerLinkActive="active mat-primary" [routerLink]="['/10000-empresas/empresas']">Empresas</a>
      <a class="sub" mat-button routerLinkActive="active mat-primary" [routerLink]="['/10000-empresas/tips']">Tips</a> -->
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <section class="sidenav-content">
      <router-outlet #route="outlet"></router-outlet>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>

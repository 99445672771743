<div class="custom-container">
  <form (submit)="downloadSubscribers()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Reporte suscripciones activas</mat-card-title>
      </mat-card-header>
      <mat-card-actions fxLayout="row">
        <div fxFlex></div>
        <button type="submit" mat-button [disabled]="loading">Descargar</button>
      </mat-card-actions>
    </mat-card>
  </form>
  <form (submit)="downloadNonSubscribers()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Reporte usuarios sin suscripción para mailing</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-checkbox [formControl]="fullNonSubscribers" color="primary">Incluir perfil</mat-checkbox>
      </mat-card-content>
      <mat-card-actions fxLayout="row">
        <div fxFlex></div>
        <button type="submit" mat-button [disabled]="loading">Descargar</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="format">
    <mat-header-cell *matHeaderCellDef> Formato </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.format}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="currency">
    <mat-header-cell *matHeaderCellDef> Moneda </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.currency}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a [routerLink]="[element.id]" mat-icon-button color="primary">
        <mat-icon>edit</mat-icon>
      </a></mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColums"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColums;">
  </mat-row>
</mat-table>
<mat-paginator [length]="dataSource.count" [pageSize]="10" [pageSizeOptions]="[5, 10, 50]"></mat-paginator>

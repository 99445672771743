import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormControl } from '@angular/forms';
import { LRPlusService } from '../../services/lrplus.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  usersCounters = null;
  email = new FormControl('', [Validators.email]);
  subscriberid = new FormControl('', [Validators.required]);
  constructor(private router: Router,
    private lrplusService: LRPlusService) { }

  ngOnInit() {
    this.lrplusService.getUsersCounters().subscribe(v => {
      this.usersCounters = v;
    });
  }
  searchUser() {
    if (this.email.valid && this.email.touched && this.email.value) {
      this.router.navigate(['la-republica-mas/usuario', this.email.value]);
    }
  }
  searchSubscriber() {
    if (this.subscriberid.valid && this.subscriberid.touched && this.subscriberid.value) {
      this.router.navigate(['la-republica-mas/suscriptor', this.subscriberid.value]);
    }
  }
}

import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsCoExtra from '@angular/common/locales/extra/es-CO';

import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MaterialModule } from './material.module';

import { TopCompaniesRoutingModule } from './top-companies-routing.module';
import { CompanyComponent } from './top-companies/components/company/company.component';
import { IndexComponent } from './top-companies/components/index/index.component';
import { HeaderTopCompaniesComponent } from './top-companies/components/shared/header-top-companies/header-top-companies.component';
import { CreateTipComponent } from './top-companies/components/create-tip/create-tip.component';
import { IndexTipsComponent } from './top-companies/components/index-tips/index-tips.component';
import { TipComponent } from './top-companies/components/tip/tip.component';
import { EditTipComponent } from './top-companies/components/edit-tip/edit-tip.component';

registerLocaleData(localeEsCo, 'es-CO', localeEsCoExtra);
@NgModule({
  declarations: [
    CompanyComponent,
    IndexComponent,
    HeaderTopCompaniesComponent,
    CreateTipComponent,
    IndexTipsComponent,
    TipComponent,
    EditTipComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    EcoFabSpeedDialModule,
    NgxFileDropModule ,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    TopCompaniesRoutingModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CO' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class TopCompaniesModule { }

<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-card *ngIf="!pqr && !loading">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button [routerLink]="['../']" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button> RADICADO INCORRECTO
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    No hay PQRs relacionados al radicado <strong [innerText]="id"></strong>
  </mat-card-content>
  <mat-card-actions>
    <div fxFlex></div>
    <button mat-raised-button [routerLink]="['../']">Volver al listado de PQRS</button>
  </mat-card-actions>
</mat-card>
<mat-card *ngIf="pqr && !loading">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button [routerLink]="['../']" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <h2>Solicitud</h2>
    <ul class="header">
      <li>
        <span>Radicado</span>
        <strong [innerText]="pqr.id"></strong>
      </li>
      <li>
        <span>Creado</span>
        <strong [innerText]="pqr.create | date:'short'"></strong>
      </li>
      <li>
        <span>Estado</span>
        <strong [innerText]="pqr.status"></strong>
      </li>
      <li>
        <span>Origen</span>
        <strong [innerText]="pqr.source"></strong>
      </li>
      <li>
        <span>Ip</span>
        <strong [innerText]="pqr.ip"></strong>
      </li>
    </ul>
    <ul class="client">
      <li>
        <span>Tipo Documento</span>
        <strong [innerText]="pqr.clientTid"></strong>
      </li>
      <li>
        <span>Número Documento</span>
        <strong [innerText]="pqr.clientId"></strong>
      </li>
      <li>
        <span>Nombre</span>
        <strong [innerText]="pqr.clientName"></strong>
      </li>
      <li>
        <span>Email</span>
        <strong [innerText]="pqr.clientEmail"></strong>
      </li>
      <li>
        <span>Teléfono</span>
        <strong [innerText]="pqr.clientMobile"></strong>
      </li>
    </ul>
    <ul class="request">
      <li>
        <span>Tipo de contacto</span>
        <strong [innerText]="pqr.contactType"></strong>
      </li>
      <li>
        <span>Asunto</span>
        <strong [innerText]="pqr.subject"></strong>
      </li>
      <li class="full">
        <span>Descripción</span>
        <p [innerText]="pqr.description"></p>
      </li>
    </ul>

    <div *ngIf="pqr.messages && pqr.messages.length">
      <h2>Respuestas</h2>
      <ul *ngFor="let message of pqr.messages" class="message">
        <li>
          <div>
            <span>Autor</span>
            <strong [innerText]="message.author"></strong>
          </div>
          <div>
            <span>Fecha</span>
            <strong [innerText]="message.created | date:'short'"></strong>
          </div>
          <div>
            <span>Estado</span>
            <strong [innerText]="message.oldStatus"></strong>
            <mat-icon>arrow_right_alt</mat-icon>
            <strong [innerText]="message.newStatus"></strong>
          </div>
        </li>
        <li class="detail">
          <span>Mensaje</span>
          <p [innerText]="message.message"></p>
        </li>
      </ul>
    </div>
    <form (submit)="addMessage(formDirective)" [formGroup]="addMessageForm"  #formDirective="ngForm" class="add-message-form">
      <h3>Nueva Respuesta</h3>
      <mat-form-field class="half pr">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="author" autocomplete="off" required>
        <mat-error>El nombre es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="half">
        <mat-label>Nuevo estado</mat-label>
        <mat-select placeholder="Seleccione" formControlName="newStatus" required>
          <mat-option *ngFor="let status of statuses" [value]="status">
            {{status}}
          </mat-option>
        </mat-select>
        <mat-error>El nuevo estado es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field class="complete">
        <mat-label>Mensaje</mat-label>
        <textarea matInput matTextareaAutosize matAutosizeMinRows="1" formControlName="message" required></textarea>
        <mat-error>El mensaje es obligatorio</mat-error>
      </mat-form-field>
      <div class="submit-button">
        <button mat-raised-button color="primary">Guardar</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

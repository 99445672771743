import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BaseService } from 'src/app/services/base.service';
import { ListTips } from '../models/list-tips.model';
import { Tip } from '../models/tip.model';

@Injectable({
  providedIn: 'root'
})
export class TipService {
  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService) { }

    searchTips(page?: number, limit?: number, sort?: string, topic?: string): Observable<ListTips> {
      const url = `${this.baseService.hostDomain}/api/tips`;
      const data: any = {};
      if (page) {
        data.page = page;
      }
      if (limit) {
        data.limit = limit;
      }
      if (sort) {
        data.sort = sort;
      }
      if (topic) {
        data.topic = topic;
      }
      return this.httpClient.get<ListTips>(url, { params: data }).pipe(
        catchError(err => {
          this.baseService.handleError(err, 'Obteniendo tips.');
          return [];
        })
      );
    }

    createTip(tip: Tip): Observable<Tip> {
      const url = `${this.baseService.hostDomain}/api/tips`;
      return this.httpClient.post<Tip>(url, tip).pipe(
        catchError(err => {
          this.baseService.handleError(err, 'Guardando Tip.');
          return [];
        })
      );
    }

    getTip(id: number): Observable<Tip> {
      const url = `${this.baseService.hostDomain}/api/tips/${id}`;
      return this.httpClient.get<Tip>(url).pipe(
        catchError(err => {
          this.baseService.handleError(err, 'Obteniendo Tip.');
          return [];
        })
      );
    }

    updateTip(tip: Tip): Observable<any> {
      const url = `${this.baseService.hostDomain}/api/tips/${tip.id}`;
      return this.httpClient.put(url, tip).pipe(
        catchError(err => {
          this.baseService.handleError(err, 'Actualizando Tip.');
          return [];
        })
      );
    }

    deleteTip(id: number): Observable<any> {
      const url = `${this.baseService.hostDomain}/api/tips/${id}`;
      return this.httpClient.delete(url).pipe(
        catchError(err => {
          this.baseService.handleError(err, 'Eliminando Tip.');
          return [];
        })
      );
    }
}

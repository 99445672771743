import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseService } from 'src/app/services/base.service';

import { TopCompany } from '../models/top-company.model';
import { NumericDatasheet } from '../models/numeric-datasheet.model';
import { Datasheet } from '../models/datasheet.model';
import { ListTopCompany } from '../models/list-top-company.model';
import { EconomicActivity } from '../models/economic-activity.model';
import { ListTips } from '../models/list-tips.model';


@Injectable({
  providedIn: 'root'
})
export class TopCompanyService {
  states: string[];
  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService) { }

  acEconomicActivities(val: string, banned: string[]): Observable<EconomicActivity[]> {
    const url = `${this.baseService.hostDomain}/api/tc/economic-activities/ac/${val}`;
    return this.httpClient.post<EconomicActivity[]>(url, banned).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Autocomplete Actividades Económicas.');
        return [];
      })
    );
  }

  acSectors(val: string, banned: string[]): Observable<string[]> {
    const url = `${this.baseService.hostDomain}/api/tc/sectors/ac/${val}`;
    return this.httpClient.post<string[]>(url, banned).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Autocomplete Sectores.');
        return [];
      })
    );
  }

  getCompanies(page?: number, limit?: number, sort?: string, topic?: string): Observable<ListTopCompany> {
    const url = `${this.baseService.hostDomain}/api/tc/list`;
    const data: any = {};
    if (page) {
      data.page = page;
    }
    if (limit) {
      data.limit = limit;
    }
    if (sort) {
      data.sort = sort;
    }
    if (topic) {
      data.topic = topic;
    }
    return this.httpClient.get<ListTopCompany>(url, { params: data }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo Empresas.');
        return [];
      })
    );
  }

  getCompany(nit: number): Observable<TopCompany> {
    const url = `${this.baseService.hostDomain}/api/tc/${nit}`;
    return this.httpClient.get<TopCompany>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo información de usuario.');
        return [];
      })
    );
  }

  getStates(): Observable<string[]> {
    const url = `${this.baseService.hostDomain}/api/tc/states`;
    return this.httpClient.get<string[]>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo información de departamentos.');
        return [];
      })
    );
  }

  saveCompany(el: TopCompany): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${el.nit}`;
    return this.httpClient.post<any>(url, {
      name: el.name,
      state: el.state,
      economicActivities: el.economicActivities.map(v => `${v.id}#${v.version}`),
      sectors: el.sectors,
      alias: el.alias,
      tags: el.tags
    }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando el valor numérico.');
        return [];
      })
    );
  }

  saveDropLogo(nit: number, formData: FormData): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${nit}/logo`;
    return this.httpClient.post<any>(url, formData).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando el logo.');
        return [];
      })
    );
  }

  saveNumeric(el: NumericDatasheet): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${el.nit}/numeric`;
    return this.httpClient.post<any>(url, {
      year: el.year,
      vectorId: el.vector.id,
      value: el.value
    }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando el valor numérico.');
        return [];
      })
    );
  }

  saveText(el: Datasheet): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${el.nit}/datasheet`;
    return this.httpClient.post<any>(url, {
      field: el.field,
      value: el.value,
      order: el.order
    }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando campo de hoja de datos.');
        return [];
      })
    );
  }

  deleteText(el: Datasheet): Observable<any> {
    const url = encodeURI(`${this.baseService.hostDomain}/api/tc/${el.nit}/datasheet/${el.field}`);
    return this.httpClient.delete<any>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Eliminando campo de hoja de datos.');
        return [];
      })
    );
  }

  publishCompany(nit: number): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${nit}/publish`;
    return this.httpClient.post<any>(url, null).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Publicando empresa.');
        return [];
      })
    );
  }

  unpublishCompany(nit: number): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/tc/${nit}/unpublish`;
    return this.httpClient.post<any>(url, null).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Publicando empresa.');
        return [];
      })
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Pqr } from '../../models/pqr.model';
import { PqrService } from '../../services/pqr.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-pqr-detail',
  templateUrl: './pqr-detail.component.html',
  styleUrls: ['./pqr-detail.component.scss']
})
export class PqrDetailComponent implements OnInit {
  id: string;
  pqr: Pqr;
  loading = true;
  statuses = [];
  submitted = false;
  saving = false;
  addMessageForm = this.fb.group({
    author: [null, Validators.required],
    newStatus: [null, Validators.required],
    message: ['', Validators.required]
  });

  constructor(
    private pqrService: PqrService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute) {
    this.statuses = pqrService.statuses;
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.GetPqr();
  }

  GetPqr() {
    this.pqrService.getPqr(this.id).subscribe(q => {
      this.pqr = q;
      this.loading = false;
    });
  }

  addMessage(formDirective: FormGroupDirective) {
    this.submitted = true;
    if (this.addMessageForm.valid && !this.saving) {
      this.saving = true;
      this.pqrService.addMessage({
        id: this.id,
        message: this.addMessageForm.value
      }).subscribe(() => {
        this.saving = false;
        this.addMessageForm.reset();
        formDirective.resetForm();
        this.addMessageForm.updateValueAndValidity();
        this.GetPqr();
        this.snackBar.open(
          'Respuesta guardada satisfactoriamente',
          'Listo', {
          duration: 5000
        });
      });
    }
  }
}

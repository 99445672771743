<h1>Administrador valores IPC</h1>

<div class="controls">
  <button [routerLink]="['nuevo']" mat-button>
    <mat-icon>add</mat-icon>
    Nuevo
  </button>
</div>

<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="s3path">
    <mat-header-cell *matHeaderCellDef> Archivo </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.s3Path }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> Correo </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.email }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="updated">
    <mat-header-cell *matHeaderCellDef> Última actualización </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.updated | date : "dd/MM/yyyy, h:mm a" }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef class="column-status">
      Estado
    </mat-header-cell>
    <mat-cell *matCellDef="let element" class="column-status">
      {{ element.status }}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="error">
    <mat-header-cell *matHeaderCellDef> Error </mat-header-cell>
    <mat-cell *matCellDef="let element">
      {{ element.error }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColums"> </mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColums"> </mat-row>
</mat-table>
<mat-paginator
  [length]="dataSource.total"
  [pageSize]="10"
  [pageSizeOptions]="[5, 10, 50]"
></mat-paginator>

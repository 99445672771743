import { Component, OnInit } from '@angular/core';
import { CategoryDataSource } from '../../datasources/category-data-source';
import { QuoteService } from '../../services/quote.service';

@Component({
  selector: 'app-category-admin-index',
  templateUrl: './category-admin-index.component.html',
  styleUrls: ['./category-admin-index.component.scss']
})
export class CategoryAdminIndexComponent implements OnInit {
  dataSource: CategoryDataSource;
  displayedColums = ['name', 'parent', 'actions'];
  constructor(quoteService: QuoteService) {
    this.dataSource = new CategoryDataSource(quoteService);
  }

  ngOnInit() {
    this.dataSource.loadCategory();
  }

}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { TipsDataSource } from '../../datasources/tips-data-source';
import { TipService } from '../../services/tip.service';
import { Tip } from '../../models/tip.model';

@Component({
  selector: 'app-index-tips',
  templateUrl: './index-tips.component.html',
  styleUrls: ['./index-tips.component.scss']
})
export class IndexTipsComponent implements OnInit, AfterViewInit {

  tipFilter: FormControl;
  dataSource: TipsDataSource;
  displayedColums = ['id', 'year', 'title', 'value', 'subvalue', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private tipService: TipService) {
    this.tipFilter = new FormControl();
    this.tipFilter.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(v => {
        this.loadTips();
      });
  }


  ngOnInit() {
    this.dataSource = new TipsDataSource(this.tipService);
    this.dataSource.loadTips(1, 50);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.loadTips();
    });
    this.paginator.page.pipe(tap(() => this.loadTips())).subscribe();
  }

  loadTips() {
    this.dataSource.loadTips(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      (this.sort.direction === 'desc' ? '-' : '') + this.sort.active,
      this.tipFilter.value
    );
  }

  deleteTip(tip: Tip) {
    this.tipService.deleteTip(tip.id).subscribe(() => {
      this.loadTips();
    });
  }
}

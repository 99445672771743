import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutocompleteResponse } from '../../models/autocomplete-response.model';
import { PushServiceService } from '../../services/push-service.service';

@Component({
  selector: 'app-confirm-send',
  templateUrl: './confirm-send.component.html',
  styleUrls: ['./confirm-send.component.scss']
})
export class ConfirmSendComponent implements OnInit {

  sending = false;
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: AutocompleteResponse,
    private bottomSheetRef: MatBottomSheetRef<ConfirmSendComponent>,
    private pushService: PushServiceService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  send() {
    if (!this.sending) {
      this.sending = true;
      this.snackBar.open('Enviando...');
      this.pushService.sendPush(this.data.id).subscribe(v => {
        this.bottomSheetRef.dismiss();
        this.snackBar.open('Notificación enviada satisfactoriamente.', 'Cerrar', {
          panelClass: 'success'
        });
      });
    }
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { tap } from "rxjs/operators";
import { IpcFilesDataSource } from "../../datasources/ipc-files-data-source";
import { IpcService } from "../../services/ipc.service";

@Component({
  selector: "app-ipc-admin-index",
  templateUrl: "./ipc-admin-index.component.html",
  styleUrls: ["./ipc-admin-index.component.scss"],
})
export class IpcAdminIndexComponent implements OnInit {
  page: number;
  pageSize: number;
  dataSource: IpcFilesDataSource;
  displayedColums = ["s3path", "email", "updated", "status", "error"];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(ipcService: IpcService) {
    this.dataSource = new IpcFilesDataSource(ipcService);
  }

  ngOnInit() {
    this.dataSource.getFiles(1, 10);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getFiles())).subscribe();
  }

  getFiles() {
    this.dataSource.getFiles(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize
    );
  }
}

<app-header-lr-plus></app-header-lr-plus>
<div class="custom-container">
  <form (submit)="searchUser()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Buscar usuarios por correo electrónico</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <input matInput placeholder="Correo electrónico" [formControl]="email" autocomplete="off">
        </mat-form-field>
        <div fxLayout="row">
          <div fxFlex></div>
          <button type="submit" mat-button>Buscar</button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <form (submit)="searchSubscriber()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Buscar suscriptores</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-form-field>
          <input matInput placeholder="Número de identificación" [formControl]="subscriberid" autocomplete="off">
          <mat-hint>Sin puntos, guiones ni dígitos de verificación</mat-hint>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions fxLayout="row">
        <div fxFlex></div>
        <button type="submit" mat-button>Buscar</button>
      </mat-card-actions>
    </mat-card>
  </form>
  <mat-card *ngIf="usersCounters" class="users-counters">
    <mat-card-content>
      <mat-list>
        <mat-list-item>
          <span mat-line>Usuarios confirmados</span>
          <b>{{usersCounters.confirmedUsers | number}}</b>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span mat-line>Suscriptores</span>
          <b>{{usersCounters.subscribers | number}}</b>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item>
          <span mat-line>Suscriptores sin cuenta confirmada</span>
          <b>{{usersCounters.subscribersNotConfirmed | number}}</b>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
      <mat-card-actions fxLayout="column" fxLayoutAlign="center end">
        <span>Última Lectura</span>
        <div>{{usersCounters.lastRead | date:'dd/MM/yyyy, hh:mm a'}}</div>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { QRangeDate } from 'src/app/modules/quotes/models/q-rangedate.model';

export const DAILY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['./rangepicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DAILY_FORMAT },
  ]
})
export class RangepickerComponent {
  @Output() changed = new EventEmitter<QRangeDate>();
  startCtrl: FormControl;
  endCtrl: FormControl;

  constructor() {
    this.startCtrl = new FormControl();
    this.endCtrl = new FormControl();
  }

  chosenDateHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    if (this.startCtrl.value &&
        this.endCtrl.value &&
        this.startCtrl.value < this.endCtrl.value) {

      this.changed.emit({
        from: this.startCtrl.value,
        to: this.endCtrl.value
      });
    }
    datepicker.close();
  }

}

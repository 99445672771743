<mat-card *ngIf="loading">
  <mat-card-title>Validando la información del usuario</mat-card-title>
  <mat-card-content>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="!loading">
  <mat-card-title>Ingreso</mat-card-title>
  <mat-card-subtitle>Para ingresar a la herramienta debe tener una cuenta de Google con los permisos necesarios. Si aún
    no dispone de esa cuenta,
    debe realizar la solicitud a través de su jefe inmediato.
    <div *ngIf="email"><strong>{{email}}</strong> no tiene permisos para acceder a la herramienta.</div>
  </mat-card-subtitle>
  <mat-card-content fxLayout="row">
    <div fxFlex></div>
    <a mat-raised-button color="primary" target="_self" href="/ingreso-externo?provider=OpenIdConnect">
      <mat-icon>account_circle</mat-icon> Ingresar
    </a>
    <div fxFlex></div>
  </mat-card-content>
</mat-card>

<div class="custom-container">
  <div class="back-header" fxLayout="row">
    <div fxFlex></div>
    <button routerLink="/la-republica-mas" color="default" mat-tooltip="Volver" mat-mini-fab>
      <mat-icon>navigate_before</mat-icon>
    </button>
  </div>
  <mat-card *ngIf="loadingModule">
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner [diameter]="80"></mat-spinner>
        <span>Cargando</span>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="!loadingModule && !userResponse">
    <mat-card-header>
      <mat-card-title>Usuario no existe</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      El usuario <strong [innerText]="email"></strong> no existe en La República +
    </mat-card-content>
  </mat-card>

  <div *ngIf="!loadingModule &&  userResponse">
    <mat-accordion [multi]="true">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Información Cuenta
        </mat-expansion-panel-header>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <h3 fxFlex="50">Email:</h3>
          <div fxFlex="50" [innerText]="userResponse.email"></div>
          <h3 fxFlex="50">Fecha de Creación:</h3>
          <div fxFlex="50" [innerText]="userResponse.created | date: 'dd/MM/yyyy, h:mm a'"></div>
          <h3 fxFlex="50">Confirmó Correo:</h3>
          <div fxFlex="50">
            <mat-icon>{{userResponse.emailConfirmed ? 'done' : 'remove'}}</mat-icon>
          </div>
          <div fxFlex="100" *ngIf="!editSubscription">
            <h3 fxFlex="50">Suscripción:</h3>
            <div fxFlex="50">
              <div fxFlexAlign="center">
                <a class="subscriber-id" *ngIf="userResponse.subscriberIsActive"
                  [routerLink]="['../../suscriptor', userResponse.subscriberId]"
                  [innerText]="userResponse.subscriberId"></a>
                <span *ngIf="!userResponse.subscriberIsActive" [innerText]="userResponse.subscriberId"></span>
                <span *ngIf="userResponse.subscriberIsActive && !userResponse.subscriberId">Cortesía </span>
                <span *ngIf="userResponse.subscriberIsActive"> (Activa)</span>
                <span *ngIf="!userResponse.subscriberIsActive"> (Inactiva)</span>
              </div>
              <button fxFlex="0 0 30px" (click)="editSubscription = true" mat-icon-button color="primary"
                [disabled]="updatingSubscription">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
          <form fxFlex="100" (submit)="updateUserSubscription()" *ngIf="editSubscription">
            <mat-form-field fxFlex="80">
              <input matInput placeholder="Número de identificación" [formControl]="newSubscriberId" autocomplete="off">
              <mat-hint>Sin puntos, guiones ni dígitos de verificación</mat-hint>
            </mat-form-field>
            <button type="submit" fxFlexAlign="center" fxFlex="0 0 30px" mat-icon-button color="primary"
              [disabled]="updatingSubscription">
              <mat-icon>save</mat-icon>
            </button>
            <button type="button" fxFlexAlign="center" fxFlex="0 0 30px" (click)="editSubscription = false"
              mat-icon-button [disabled]="updatingSubscription">
              <mat-icon>clear</mat-icon>
            </button>
          </form>
          <div fxFlex="100" *ngIf="userResponse.subscriptionUpdated">
            <h3 fxFlex="50">Fecha Actualización Suscripción:</h3>
            <div fxFlex="50" fxFlexAlign="center"
              [innerText]="userResponse.subscriptionUpdated | date: 'dd/MM/yyyy, h:mm a'"></div>
          </div>
          <div fxFlex="100" *ngIf="userResponse.courtesyValidUntil">
            <h3 fxFlex="50">Cortesía Valida Hasta:</h3>
            <div fxFlex="50" fxFlexAlign="center"
              [innerText]="userResponse.courtesyValidUntil | date: 'dd/MM/yyyy, h:mm a'"></div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Notificaciones
        </mat-expansion-panel-header>
        <mat-table [dataSource]="userResponse.notifications">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="lastSend">
            <mat-header-cell *matHeaderCellDef> Último Envío </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.lastSend | date: 'dd/MM/yyyy, h:mm a'}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="allowSend">
            <mat-header-cell *matHeaderCellDef> Permite Envíos </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-checkbox color="primary" (change)="updateNotif(element)" [(ngModel)]="element.allowSend">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="notifColumns"> </mat-header-row>
          <mat-row *matRowDef="let row; columns: notifColumns;">
          </mat-row>
        </mat-table>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Últimos Correos Enviados
        </mat-expansion-panel-header>
        <mat-table [dataSource]="userResponse.mails">
          <ng-container matColumnDef="subject">
            <mat-header-cell *matHeaderCellDef> Asunto </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.subject}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="sended">
            <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sended | date: 'dd/MM/yyyy, h:mm a'}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="mailsColumns"> </mat-header-row>
          <mat-row *matRowDef="let row; columns: mailsColumns;">
          </mat-row>
        </mat-table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Tip } from '../../models/tip.model';

@Component({
  selector: 'app-create-tip',
  templateUrl: './create-tip.component.html',
  styleUrls: ['./create-tip.component.scss']
})
export class CreateTipComponent implements OnInit {

  tip: Tip = {
    id: 0,
    title: '',
    value: '',
    subValue: '',
    description: '',
    year: null
  };
  constructor() { }

  ngOnInit() {
  }

}

<div class="custom-container">
  <div class="back-header" fxLayout="row">
    <div fxFlex></div>
    <button routerLink="/la-republica-mas" color="default" mat-tooltip="Volver" mat-mini-fab>
      <mat-icon>navigate_before</mat-icon>
    </button>
  </div>
  <mat-card *ngIf="loadingModule">
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner [diameter]="80"></mat-spinner>
        <span>Cargando</span>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="!loadingModule && (subscriberResponse === null || !subscriberResponse?.exists)">
    <mat-card-header>
      <mat-card-title>No Existe Suscripción</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      El número de identificación <strong [innerText]="id"></strong> no corresponde a ninguna suscripción
    </mat-card-content>
  </mat-card>

  <div *ngIf="subscriberResponse && subscriberResponse.exists">
    <mat-card>
      <mat-card-header>
        <mat-card-title [innerText]="id"></mat-card-title>
        <mat-card-subtitle>Subscripción Activa</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <mat-icon color="warn" *ngIf="subscriberResponse.relatedUsers > subscriberResponse.allowedAccounts">
              warning
            </mat-icon>
            <h2>{{subscriberResponse.relatedUsers}} cuenta(s) de {{subscriberResponse.allowedAccounts}}
              permitida(s)</h2>
          </mat-list-item>
          <div class="add-user-form" *ngIf="subscriberResponse.relatedUsers < subscriberResponse.allowedAccounts">
            <form (submit)="addUser()">
              <mat-form-field>
                <input matInput placeholder="Agregar correo electrónico" [readonly]="loading" [formControl]="newEmail"
                  autocomplete="off">
              </mat-form-field>
              <div fxLayout="row">
                <div fxFlex></div>
                <button type="submit" mat-button [disabled]="loading">Agregar</button>
              </div>
            </form>
          </div>
          <mat-divider *ngIf="subscriberResponse.relatedUsers > 0"></mat-divider>
          <div *ngIf="subscriberResponse.confirmedUsers && subscriberResponse.confirmedUsers.length > 0">
            <h3 mat-subheader>Usuarios con email confirmado</h3>
            <mat-list-item class="confirmed-users" *ngFor="let user of subscriberResponse.confirmedUsers">
              <mat-icon mat-list-icon>email</mat-icon>
              <a class="active-user" mat-line *ngIf="user.confirmedEmail" [routerLink]="['../../usuario', user.email]">
                {{user.email}} </a>
              <p mat-line *ngIf="!user.confirmedEmail"> {{user.email}} </p>
              <button (click)="removeEmail(user.email)" mat-icon-button color="warn" [disabled]="loading">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-list-item>
          </div>
          <div *ngIf="subscriberResponse.unconfirmedUsers && subscriberResponse.unconfirmedUsers.length > 0">
            <h3 mat-subheader>Usuarios con email sin confirmar</h3>
            <mat-list-item *ngFor="let user of subscriberResponse.unconfirmedUsers">
              <mat-icon mat-list-icon>email</mat-icon>
              <a mat-line *ngIf="user.confirmedEmail" [routerLink]="['../../usuario', user.email]"> {{user.email}} </a>
              <p mat-line *ngIf="!user.confirmedEmail"> {{user.email}} </p>
              <button (click)="removeEmail(user.email)" mat-icon-button color="warn" [disabled]="loading">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-list-item>
          </div>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>

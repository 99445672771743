import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../services/quote.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { QuoteAndValueResponse } from '../../models/quote-and-value-response.model';
import { QRangeDate } from '../../models/q-rangedate.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormControl } from '@angular/forms';

export const DAILY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'app-quote-index',
  templateUrl: './quote-index.component.html',
  styleUrls: ['./quote-index.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DAILY_FORMAT },
  ]
})
export class QuoteIndexComponent implements OnInit {
  frecuencies: string[];
  selectedFrecuency: string;
  fromDailyCtrl = new FormControl(moment().add(1, 'days'));
  fromMonthCtrl = new FormControl(moment());
  res: QuoteAndValueResponse;
  constructor(private quoteService: QuoteService) {
    this.fromDailyCtrl.valueChanges.subscribe(v => {
      this.getQuotesAndValues(v);
    });
  }
  ngOnInit() {
    this.getFrecuencies();
  }
  getFrecuencies() {
    this.quoteService.getFrecuencies().subscribe(v => {
      this.frecuencies = v;
      this.selectedFrecuency = this.frecuencies[0];
    });
  }
  tabChanged(evt: MatTabChangeEvent) {
    console.log('cambio de tab');
    this.selectedFrecuency = evt.tab.textLabel;
    let defaultDate: Moment;
    switch (this.selectedFrecuency) {
      case 'MENSUAL':
        defaultDate = moment();
        defaultDate.date(1);
        break;
      case 'DESDE-HASTA':
        this.res = null;
        return;
      case 'ANUAL':
        defaultDate = moment();
        defaultDate.date(1);
        defaultDate.month(0);
        break;
      case 'TRIMESTRAL':
        defaultDate = moment();
        defaultDate.date(1);
        defaultDate.month(parseInt(`${defaultDate.month() / 3}`, 10) * 3);
        break;
      default:
        defaultDate = moment().add(1, 'days');
        break;
    }
    this.getQuotesAndValues(defaultDate);
  }

  getQuotesAndValues(date: Moment) {
    this.quoteService.getQuotesAndValues(this.selectedFrecuency, date)
      .subscribe(v => this.res = v);
  }

  getQuotesAndValuesByRange(rangeDate: QRangeDate) {
    this.quoteService.getQuotesAndValues(this.selectedFrecuency, rangeDate.from, rangeDate.to)
      .subscribe(v => this.res = v);
  }

  readClipboard(value: string) {
    const rows = value.replace(/[,]/g, '.').replace(/[\r]/g, '').split('\n');
    const fieldValues = [];
    rows.forEach(function (element) {
      const val = element.split('\t', 1)[0];
      fieldValues.push(val);
    });
    let idx = 0;
    if (fieldValues.length && this.res && this.res.categoryGroups.length) {
      for (let i = 0; i < this.res.categoryGroups.length; i++) {
        if (this.res.categoryGroups[i].quotes &&
          this.res.categoryGroups[i].quotes.length &&
          idx < fieldValues.length) {
          for (let j = 0; j < this.res.categoryGroups[i].quotes.length; j++) {
            if (idx < fieldValues.length) {
              const val = Number(fieldValues[idx]);
              if (fieldValues[idx] && !Number.isNaN(val)) {
                const modValue = this.res.categoryGroups[i].quotes[j];
                modValue.actualValue.value = val;
                this.res.categoryGroups[i].quotes[j] = modValue;
              }
              idx++;
            } else {
              break;
            }
          }
        } else {
          break;
        }
      }
    }
  }

  pasteFromClipboard() {
    (navigator as any).clipboard.readText().then((v: string) => this.readClipboard(v), function () { });
  }
}

import { Component, OnInit } from "@angular/core";
import { IpcService } from "../../services/ipc.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";

@Component({
  selector: "app-load-ipc-file",
  templateUrl: "./load-ipc-file.component.html",
  styleUrls: ["./load-ipc-file.component.scss"],
})
export class LoadIpcFileComponent implements OnInit {
  isSaving: boolean = false;
  file: File = null;
  private FILE_FIELD_NAME: string = "file";

  constructor(
    private snackBar: MatSnackBar,
    private ipcService: IpcService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  dropFile(e: NgxFileDropEntry[]) {
    if (e.length > 1) {
      this.snackBar.open("Debe arrastrar solo un archivo", "cerrar");
      return;
    }
    const file = e[0];
    if (!file.fileEntry.isFile || !file.fileEntry.name.endsWith(".xlsx")) {
      this.snackBar.open("Sólo se admiten archivos .xlsx", "cerrar");
      return;
    }
    const fileEntry = file.fileEntry as FileSystemFileEntry;
    fileEntry.file((f: File) => {
      this.file = f;
    });
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const f: File = fileList[0];
      this.file = f;
    }
  }

  loadFile() {
    if (!this.isSaving) {
      if (!this.file) {
        this.snackBar.open(
          "Seleccione el archivo con los datos del IPC",
          "cerrar"
        );
        return;
      }
      this.isSaving = true;
      let formParams = new FormData();
      formParams.append(this.FILE_FIELD_NAME, this.file);
      this.ipcService.loadIpcFile(formParams).subscribe(
        () => {
          setTimeout(() => {
            this.router.navigateByUrl("/indicadores/ipc");
          }, 1000);
        },
        () => {
          this.isSaving = false;
        }
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { QuoteAndValueResponse } from '../models/quote-and-value-response.model';
import * as moment from 'moment';
import { Moment } from 'moment';
import { QValue } from '../models/q-value.model';
import { QQuote } from '../models/q-quote.model';
import { QuoteResponse } from '../models/quote-response.model';
import { BVCInfoResponse } from '../models/bvcinfo-response.model';
import { QBVCInfo } from '../models/q-bvcinfo.model';
import { QCategory } from '../models/q-category.model';
@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private httpClient: HttpClient,
    private baseService: BaseService) { }

  getCategories(): Observable<QCategory[]> {
    const url = `${this.baseService.hostDomain}/api/quote/categories`;
    return this.httpClient.get<QCategory[]>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo categorías.');
        return [];
      })
    );
  }

  getCategoryById(id: number): Observable<QCategory> {
    const url = `${this.baseService.hostDomain}/api/quote/categories/${id}`;
    return this.httpClient.get<QCategory>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo categoría.');
        return [];
      })
    );
  }

  saveCategory(cat: QCategory): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/quote/categories/${cat.id}`;
    return this.httpClient.put<any>(url, cat).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando categoría.');
        return [];
      })
    );
  }

  getQuotes(page?: number, pagesize?: number): Observable<QuoteResponse> {
    const url = `${this.baseService.hostDomain}/api/quote`;
    const data: any = {};
    if (page) {
      data.page = page;
    }
    if (pagesize) {
      data.pagesize = pagesize;
    }
    return this.httpClient.get<QuoteResponse>(url, { params: data }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo indicadores.');
        return [];
      })
    );
  }

  getQuote(id: number): Observable<QQuote> {
    const url = `${this.baseService.hostDomain}/api/quote/${id}`;
    return this.httpClient.get<QQuote>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo indicador.');
        return [];
      })
    );
  }

  getFrecuencies(): Observable<string[]> {
    const url = `${this.baseService.hostDomain}/api/quote/frecuencies`;
    return this.httpClient.get<string[]>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo indicadores.');
        return [];
      })
    );
  }

  getQuotesAndValues(frecuency: string, from: Moment, to?: Moment): Observable<QuoteAndValueResponse> {
    let url = `${this.baseService.hostDomain}/api/quote/quotes/${frecuency}/${from.format('YYYY-MM-DD')}`;
    if (to) {
      url = `${url}/${to.format('YYYY-MM-DD')}`;
    }
    return this.httpClient.get<QuoteAndValueResponse>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo indicadores y valores.');
        return [];
      })
    );
  }

  saveQuoteValue(id: number, value: QValue): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/quote/values/${id}`;
    return this.httpClient.post<any>(url, value).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando indicador.');
        return [];
      })
    );
  }

  saveQuote(quote: QQuote): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/quote/${quote.id}`;
    return this.httpClient.post<any>(url, quote).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando indicador.');
        return [];
      })
    );
  }

  getBVCInfo(page?: number, pagesize?: number): Observable<BVCInfoResponse> {
    const url = `${this.baseService.hostDomain}/api/quote/bvcinfo`;
    const data: any = {};
    if (page) {
      data.page = page;
    }
    if (pagesize) {
      data.pagesize = pagesize;
    }
    return this.httpClient.get<BVCInfoResponse>(url, { params: data }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo acciones.');
        return [];
      })
    );
  }

  getBVCInfoById(name: string): Observable<QBVCInfo> {
    const url = `${this.baseService.hostDomain}/api/quote/bvcinfo/${name}`;
    return this.httpClient.get<QBVCInfo>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo acción.');
        return [];
      })
    );
  }

  saveBVCInfo(item: QBVCInfo): Observable<any>{
    const url = `${this.baseService.hostDomain}/api/quote/bvcinfo/${item.name}`;
    return this.httpClient.post<any>(url, item).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando acción.');
        return [];
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';
import { PqrIndexComponent } from './pqr/components/pqr-index/pqr-index.component';
import { PqrDetailComponent } from './pqr/components/pqr-detail/pqr-detail.component';
import { PqrCreateComponent } from './pqr/components/pqr-create/pqr-create.component';

const routes: Routes = [
  { path: 'pqr/crear', component: PqrCreateComponent, canActivate: [AuthGuardService] },
  { path: 'pqr/:id', component: PqrDetailComponent, canActivate: [AuthGuardService] },
  { path: 'pqr', component: PqrIndexComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PqrRoutingModule { }

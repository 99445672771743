import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { TopCompanyService } from '../services/top-company.service';
import { map, finalize } from 'rxjs/operators';
import { TopCompanyLite } from '../models/top-company-lite.model';

export class TopCompaniesDataSource implements DataSource<TopCompanyLite> {
  private topCompaniesSubject = new BehaviorSubject<TopCompanyLite[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private topCompanyService: TopCompanyService) { }

  connect(_collectionViewer: CollectionViewer): Observable<TopCompanyLite[]> {
    return this.topCompaniesSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.topCompaniesSubject.complete();
    this.loadingSubject.complete();
  }

  loadTopCompanies(page?: number, limit?: number, sort?: string, topic?: string) {
    this.loadingSubject.next(true);
    this.topCompanyService.getCompanies(page, limit, sort, topic)
      .pipe(
        map(v => {
          this.count = v.count;
          return v.companies;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.topCompaniesSubject.next(v));
  }
}

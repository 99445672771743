import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuoteIndexComponent } from "./components/quote-index/quote-index.component";
import { AuthGuardService } from "src/app/services/auth-guard.service";
import { QuoteAdminComponent } from "./components/quote-admin/quote-admin.component";
import { QuoteAdminIndexComponent } from "./components/quote-admin-index/quote-admin-index.component";
import { BvcinfoAdminIndexComponent } from "./components/bvcinfo-admin-index/bvcinfo-admin-index.component";
import { BvcinfoAdminComponent } from "./components/bvcinfo-admin/bvcinfo-admin.component";
import { CategoryAdminIndexComponent } from "./components/category-admin-index/category-admin-index.component";
import { CategoryAdminComponent } from "./components/category-admin/category-admin.component";
import { IpcAdminIndexComponent } from "./components/ipc-admin-index/ipc-admin-index.component";
import { LoadIpcFileComponent } from "./components/load-ipc-file/load-ipc-file.component";

const routes: Routes = [
  {
    path: "indicadores/admin/:id",
    component: QuoteAdminComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/admin",
    component: QuoteAdminIndexComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/bvcadmin/:name",
    component: BvcinfoAdminComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/bvcadmin",
    component: BvcinfoAdminIndexComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/categorias/:id",
    component: CategoryAdminComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/categorias",
    component: CategoryAdminIndexComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/ipc/nuevo",
    component: LoadIpcFileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores/ipc",
    component: IpcAdminIndexComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "indicadores",
    component: QuoteIndexComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QuotesRoutingModule {}

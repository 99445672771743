import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LRPlusService } from '../../services/lrplus.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  fullNonSubscribers = new FormControl(false);
  loading: boolean;
  constructor(private lrplusService: LRPlusService) { }

  ngOnInit(): void {
  }

  downloadSubscribers() {
    this.loading = true;
    this.lrplusService.downloadSubscribers();
  }

  downloadNonSubscribers() {
    this.loading = true;
    this.lrplusService.downloadNonSubscribers(this.fullNonSubscribers.value);
  }
}

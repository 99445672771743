<div class="input-row" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
  <mat-form-field [ngClass]="{'mat-form-field-invalid mat-error': !rrr.validity.valid}" fxFlex="50">
    <mat-label>{{cQuote.quote.name}}</mat-label>
    <input #rrr type="number" step="0.00000001" matInput [formControl]="formCtrl" [readonly]="saving" autocomplete="off">
    <span *ngIf="!isPercent && cQuote.quote.currency == 'USD'" matPrefix>US$&nbsp;</span>
    <span *ngIf="!isPercent && cQuote.quote.currency != 'USD'" matPrefix>$&nbsp;</span>
    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="cleanFormControl()">
      <mat-icon>close</mat-icon>
    </button>
    <span *ngIf="isPercent" matSuffix>%</span>
    <mat-hint>
      <span>{{cQuote.quote.scale}}</span>
      <span>{{saving ? ' - guardando' : ''}}</span>
      <span *ngIf="saved">
        <mat-icon>done</mat-icon>
        Guardado
      </span>
      <span [ngClass]="{'mat-form-field-invalid mat-error': !rrr.validity.valid}" *ngIf="!rrr.validity.valid">El número no se ha podido procesar</span>
    </mat-hint>
  </mat-form-field>
  <div fxFlex="10"></div>
  <div>
    <div *ngIf="!isPercent && cQuote.quote.currency == 'USD'">Último Valor: {{cQuote.lastValue?.value | currency:'USD':'symbol':'0.0-8' }}</div>
    <div *ngIf="!isPercent && cQuote.quote.currency != 'USD'">Último Valor: {{cQuote.lastValue?.value | currency:'COP':'symbol':'0.0-8' }}</div>
    <div *ngIf="isPercent">Último Valor: {{(cQuote.lastValue?.value / 100) | percent:'0.0-8' }}</div>
    <div>Fecha: {{cQuote.lastValue?.from | date }} {{cQuote.lastValue.to ? '-' : ''}} {{cQuote.lastValue?.to | date }}</div>
  </div>

  <mat-icon
    *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value > cQuote.lastValue.value">
    arrow_upward</mat-icon>
  <mat-icon
    *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value < cQuote.lastValue.value">
    arrow_downward</mat-icon>
  <mat-icon
    *ngIf="cQuote.actualValue.value && cQuote.lastValue.value && cQuote.actualValue.value == cQuote.lastValue.value">
    remove</mat-icon>
  <mat-icon *ngIf="!cQuote.actualValue.value || !cQuote.lastValue.value">priority_hight</mat-icon>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { Observable } from 'rxjs';
import { AutocompleteResponse } from '../models/autocomplete-response.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PushServiceService {
  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService) { }

  autocomplete(topic: string): Observable<AutocompleteResponse[]> {
    const url = `${this.baseService.hostDomain}/api/app-notification/ac/${topic}`;
    return this.httpClient.get<AutocompleteResponse[]>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Autocomplete Noticias.');
        return [];
      })
    );
  }

  sendPush(postId: number): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/app-notification/sendpush/${postId}`;
    return this.httpClient.get<any>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Enviando notificación.');
        return [];
      })
    );
  }
}

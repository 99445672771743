import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../../services/quote.service';
import { QCategory } from '../../models/q-category.model';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-category-admin',
  templateUrl: './category-admin.component.html',
  styleUrls: ['./category-admin.component.scss']
})
export class CategoryAdminComponent implements OnInit {
  id: number;
  editCategoryForm = this.fb.group({
    id: [null, Validators.required],
    name: [null, Validators.required],
    parent: [null],
    principalSection: [null],
    description: [null],
    weight: [0, Validators.required]
  });
  submitted = false;
  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private snackBar: MatSnackBar) {

  }

  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getCategory();
  }

  getCategory() {
    this.quoteService.getCategoryById(this.id).subscribe(v => {
      this.editCategoryForm.setValue(v);
    });
  }

  saveCategory() {
    this.submitted = true;
    if (this.editCategoryForm.valid) {
      this.quoteService
        .saveCategory(this.editCategoryForm.value)
        .subscribe(v => {
          if (v.success) {
            this.snackBar.open('Ha actualizado la categoría satisfactoriamente', 'Listo');
          }
        });
    }
  }
}

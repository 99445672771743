import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseService } from './services/base.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Administrador';
  constructor(
    private baseService: BaseService,
    public snackBar: MatSnackBar,
    public userService: UserService
  ) {
    baseService.throwErrorSource$.subscribe(msg => {
      snackBar.open(msg, 'Cerrar');
    });
  }
}

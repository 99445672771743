import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { TopCompanyService } from 'src/app/modules/top-companies/services/top-company.service';
import { ActivatedRoute } from '@angular/router';
import { TopCompany } from 'src/app/modules/top-companies/models/top-company.model';
import { NumericDatasheet } from 'src/app/modules/top-companies/models/numeric-datasheet.model';
import { Datasheet } from 'src/app/modules/top-companies/models/datasheet.model';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, groupBy, startWith, switchMap, filter } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EconomicActivity } from 'src/app/modules/top-companies/models/economic-activity.model';
import { DatasheetDataSource } from 'src/app/modules/top-companies/datasources/datasheet-data-source';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {
  private numericSource = new Subject<NumericDatasheet>();
  numericSource$ = this.numericSource.asObservable();
  private textSource = new Subject<Datasheet>();
  textSource$ = this.textSource.asObservable();
  private companySource = new Subject<TopCompany>();
  companySource$ = this.companySource.asObservable();

  private nit: number;
  topCompany: TopCompany;
  numericDisplayedColumns = ['value', 'updated', 'updatedBy'];
  textDisplayedColumns = ['field', 'value', 'order', 'updatedc', 'actions'];
  states: string[];
  @ViewChild('eActivitiesInput', { static: false }) eActivitiesInput: ElementRef<HTMLInputElement>;
  economicActivitiesACControl = new FormControl();
  filteredEconomicActivities: Observable<EconomicActivity>;
  @ViewChild('sectorsInput', { static: false }) sectorsInput: ElementRef<HTMLInputElement>;
  sectorsACControl = new FormControl();
  filteredSectors: Observable<string[]>;
  dataSource: DatasheetDataSource;
  menuIsOpen = false;
  loading = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private topCompanyService: TopCompanyService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
    this.economicActivitiesACControl = new FormControl();
    this.sectorsACControl = new FormControl();
    this.filteredEconomicActivities = this.economicActivitiesACControl.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(val => {
          if (val) {
            return topCompanyService.acEconomicActivities(val, this.topCompany.economicActivities.map(q => q.id));
          } else {
            return [];
          }
        })
      );

    this.filteredSectors = this.sectorsACControl.valueChanges
      .pipe(
        startWith(null),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(val => {
          if (val) {
            return topCompanyService.acSectors(val, this.topCompany.sectors);
          } else {
            return [];
          }
        })
      );
  }

  ngOnInit() {
    this.dataSource = new DatasheetDataSource();
    this.nit = +this.activatedRoute.snapshot.paramMap.get('id');
    this.topCompanyService.getCompany(this.nit).subscribe(v => {
      this.topCompany = v;
      this.dataSource.loadDatasheets(this.topCompany.dataSheet);
      this.loading = false;
    });

    this.textSource$.pipe(groupBy(v => v.field)).subscribe(av => {
      av.pipe(filter(a => !a.status)).pipe(debounceTime(1000)).subscribe(v => {
        // console.log(v);
        this.saveTextField(v);
      });
    });

    this.numericSource$.pipe(groupBy(v => v.vector.id)).subscribe(av => {
      av.pipe(debounceTime(1000)).subscribe(v => {
        // console.log(v);
        this.saveNumericData(v);
      });
    });

    this.companySource$
      .pipe(filter(f => !f.needSave))
      .pipe(debounceTime(1000)).subscribe(() => {
        this.saveCompany();
      });

    if (!this.topCompanyService.states) {
      this.topCompanyService.getStates().subscribe(v => {
        this.topCompanyService.states = v;
        this.states = this.topCompanyService.states;
      });
    } else {
      this.states = this.topCompanyService.states;
    }
  }

  ngOnDestroy(): void {
    this.numericSource.complete();
    this.numericSource.unsubscribe();
    this.textSource.complete();
    this.textSource.unsubscribe();
    this.companySource.complete();
    this.companySource.unsubscribe();
  }

  saveNumericData(el: NumericDatasheet) {
    el.saving = true;
    this.topCompanyService.saveNumeric(el).subscribe(() => {
      this.topCompany.modified = new Date();
      el.saving = false;
      el.updated = new Date();
      el.updatedBy = this.userService.user.email;
    });
  }

  updateNumericField(val: number, el: NumericDatasheet) {
    el.value = val;
    this.numericSource.next(el);
  }

  economicActivitySelected(evt: MatAutocompleteSelectedEvent) {
    this.topCompany.economicActivities.push(evt.option.value);
    this.eActivitiesInput.nativeElement.value = '';
    this.companySource.next(this.topCompany);
  }

  sectorSelected(evt: MatAutocompleteSelectedEvent) {
    this.topCompany.sectors.push(evt.option.value);
    this.sectorsInput.nativeElement.value = '';
    this.companySource.next(this.topCompany);
  }

  removeEconomicActivity(idx: number) {
    this.topCompany.economicActivities.splice(idx, 1);
    this.companySource.next(this.topCompany);
  }

  removeSector(idx: number) {
    this.topCompany.sectors.splice(idx, 1);
    this.companySource.next(this.topCompany);
  }

  addNewTextField() {
    const toAdd: Datasheet = {
      order: 1,
      nit: this.topCompany.nit,
      field: null,
      value: null,
      updated: new Date(),
      updatedBy: this.userService.user.email,
      status: 'creating',
      saving: false,
      deleting: false
    };
    this.topCompany.dataSheet.push(toAdd);
    this.dataSource.loadDatasheets(this.topCompany.dataSheet);
  }

  addNewYear() {
    const newGroup = _.cloneDeep(this.topCompany.numericGroups[0]);
    newGroup.year = newGroup.year + 1;
    newGroup.numericDatasheets = newGroup.numericDatasheets.map(v => {
      v.year = v.year + 1;
      v.value = null;
      return v;
    });
    this.topCompany.numericGroups.unshift(newGroup);
  }

  removeTextField(el: Datasheet, idx: number) {
    el.deleting = true;
    this.topCompanyService.deleteText(el).subscribe(() => {
      el.deleting = false;
      this.topCompany.dataSheet.splice(idx, 1);
      this.dataSource.loadDatasheets(this.topCompany.dataSheet);
    });
  }

  cancelTextField(idx: number) {
    this.topCompany.dataSheet.splice(idx, 1);
    this.dataSource.loadDatasheets(this.topCompany.dataSheet);
  }

  updateTextValueField(val: string, el: Datasheet) {
    el.value = val;
    this.textSource.next(el);
  }

  updateTextOrderField(val: number, el: Datasheet) {
    el.order = val;
    this.textSource.next(el);
  }

  saveTextField(el: Datasheet) {
    if (!el.field || !el.value) {
      this.snackBar.open('Es necesario ingresar el nombre del campo y el valor.', null, {
        duration: 4000
      });
    } else {
      el.saving = true;
      this.topCompanyService.saveText(el).subscribe(() => {
        this.topCompany.modified = new Date();
        el.saving = false;
        el.status = null;
      });
    }
  }

  saveCompany() {
    if (!this.topCompany.name
      || !this.topCompany.state
      || !this.topCompany.economicActivities
      || this.topCompany.economicActivities.length === 0) {
      this.snackBar.open('El nombre, departamento y actividad económica son obligatorios.', null, {
        duration: 4000
      });
    } else {
      this.topCompanyService.saveCompany(this.topCompany).subscribe(v => {
        this.topCompany.modified = new Date();
        this.topCompany.needSave = false;
      });
    }
  }

  nameChange(val: string) {
    this.topCompany.name = val;
    this.companySource.next(this.topCompany);
  }

  stateChange(val: string) {
    this.topCompany.state = val;
    this.companySource.next(this.topCompany);
  }

  publish() {
    this.topCompanyService.publishCompany(this.topCompany.nit).subscribe(() => {
      this.topCompany.published = new Date();
      this.snackBar.open('Publicado satisfactoriamente.', null, {
        duration: 4000
      });
    });
  }

  unpublish() {
    this.topCompanyService.unpublishCompany(this.topCompany.nit).subscribe(() => {
      this.topCompany.published = null;
      this.snackBar.open('Eliminado satisfactoriamente.', null, {
        duration: 4000
      });
    });
  }

  diffDates(a: Date, b: Date): number {
    if (a && b) {
      return moment(a).unix() - moment(b).unix();
    }
    return 0;
  }

  dropLogo(e: NgxFileDropEntry[]) {
    if (e.length > 1) {
      this.snackBar.open('Debe arrastrar solo un archivo', 'cerrar');
      return;
    }
    const file = e[0];
    if (!file.fileEntry.isFile || (!file.fileEntry.name.endsWith('.jpg') && !file.fileEntry.name.endsWith('.png'))) {
      this.snackBar.open('Sólo se admiten archivos .jpg y .png', 'cerrar');
      return;
    }
    const fileEntry = file.fileEntry as FileSystemFileEntry;
    fileEntry.file((f: File) => {
      const formData = new FormData();
      formData.append('logo', f, f.name);
      this.topCompanyService.saveDropLogo(this.topCompany.nit, formData)
        .subscribe(v => {
          this.topCompany.logo = v.path;
        });
    });
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const f: File = fileList[0];
      const formData = new FormData();
      formData.append('logo', f, f.name);
      this.topCompanyService.saveDropLogo(this.topCompany.nit, formData)
        .subscribe(v => {
          this.topCompany.logo = v.path;
        });
    }
  }

  addAlias(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (!this.topCompany.alias) {
        this.topCompany.alias = [];
      }
      this.topCompany.alias.push(value.trim());
      this.companySource.next(this.topCompany);
    }

    if (input) {
      input.value = '';
    }
  }

  removeAlias(intItem: string): void {
    const index = this.topCompany.alias.indexOf(intItem);

    if (index >= 0) {
      this.topCompany.alias.splice(index, 1);
      this.companySource.next(this.topCompany);
    }
  }

  addTags(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      if (!this.topCompany.tags) {
        this.topCompany.tags = [];
      }
      this.topCompany.tags.push(value.trim());
      this.companySource.next(this.topCompany);
    }

    if (input) {
      input.value = '';
    }
  }

  removeTags(intItem: string): void {
    const index = this.topCompany.tags.indexOf(intItem);

    if (index >= 0) {
      this.topCompany.tags.splice(index, 1);
      this.companySource.next(this.topCompany);
    }
  }
}

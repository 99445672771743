import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsRoutingModule } from './notifications-routing.module';
import { IndexComponent } from './notifications/components/index/index.component';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmSendComponent } from './notifications/components/confirm-send/confirm-send.component';

@NgModule({
  declarations: [IndexComponent, ConfirmSendComponent],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NotificationsRoutingModule,
    MaterialModule
  ],
  entryComponents: [
    ConfirmSendComponent
  ],
})
export class NotificationsModule { }

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import * as moment from 'moment';
import { Moment } from 'moment';
export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY'
  },
};
@Component({
  selector: 'app-yearpicker',
  templateUrl: './yearpicker.component.html',
  styleUrls: ['./yearpicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS },
  ]
})
export class YearpickerComponent {
  @Output() changed = new EventEmitter<Moment>();
  yearCtrl: FormControl;
  constructor() {
    const actualYear = moment();
    actualYear.month(0);
    actualYear.date(1);
    this.yearCtrl = new FormControl(actualYear);
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.yearCtrl.value;
    ctrlValue.year(normalizedYear.year());
    this.yearCtrl.setValue(ctrlValue);
    this.changed.emit(ctrlValue);
    datepicker.close();
  }
}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from 'src/app/custom/custom-error-state-matcher';
import { PqrDataSource } from '../../datasources/pqr-data-source';
import { MatPaginator } from '@angular/material/paginator';
import { PqrService } from '../../services/pqr.service';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'app-pqr-index',
  templateUrl: './pqr-index.component.html',
  styleUrls: ['./pqr-index.component.scss']
})
export class PqrIndexComponent implements OnInit, AfterViewInit {
  idFormControl = new FormControl('', [
    Validators.required
  ]);
  matcher = new CustomErrorStateMatcher();

  dataSource: PqrDataSource;
  displayedColums = ['id', 'create', 'clientName', 'subject', 'status'];
  statuses = [];
  statusFilter: FormControl;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private router: Router,
    private pqrService: PqrService) {
    this.statuses = pqrService.statuses;
    this.statusFilter = new FormControl();
    this.statusFilter.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(v => {
        this.paginator.pageIndex = 0;
        this.loadPqrs();
      });
  }

  ngOnInit() {
    this.dataSource = new PqrDataSource(this.pqrService);
    this.dataSource.loadPqrs(1, 10);
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.loadPqrs())).subscribe();
  }

  doSearch() {
    const id = this.idFormControl.value;
    this.router.navigate(['/pqr/', id]);
  }
  loadPqrs() {
    this.dataSource.loadPqrs(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      this.statusFilter.value
    );
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "src/app/services/base.service";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { IpcFilesResponse } from "../models/ipc-files-response.model";
@Injectable({
  providedIn: "root",
})
export class IpcService {
  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService
  ) {}

  getFiles(take: number, skip: number): Observable<IpcFilesResponse> {
    const url = `${this.baseService.hostDomain}/api/quote/ipc/files-log`;
    const data: any = {};
    if (take) {
      data.take = take;
    }
    if (skip) {
      data.skip = skip;
    }
    return this.httpClient.get<IpcFilesResponse>(url, { params: data }).pipe(
      catchError((err) => {
        this.baseService.handleError(err, "Obteniendo archivos procesados.");
        return [];
      })
    );
  }

  loadIpcFile(formData: FormData): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/quote/ipc/load-file`;
    return this.httpClient.post<any>(url, formData).pipe(
      catchError((err) => {
        this.baseService.handleError(
          err,
          "Guardando el archivo para procesar."
        );
        return [];
      })
    );
  }
}

import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { map, finalize } from 'rxjs/operators';
import { QuoteService } from '../services/quote.service';
import { QBVCInfo } from '../models/q-bvcinfo.model';

export class BVCInfoDataSource implements DataSource<QBVCInfo> {
  private bvcInfoSubject = new BehaviorSubject<QBVCInfo[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private quoteService: QuoteService) { }

  connect(_collectionViewer: CollectionViewer): Observable<QBVCInfo[]> {
    return this.bvcInfoSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.bvcInfoSubject.complete();
    this.loadingSubject.complete();
  }

  loadBVCInfo(page?: number, pagesize?: number) {
    this.loadingSubject.next(true);
    this.quoteService.getBVCInfo(page, pagesize)
      .pipe(
        map(v => {
          this.count = v.total;
          return v.items;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.bvcInfoSubject.next(v));
  }
}

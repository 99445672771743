<div *ngIf="loading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-card *ngIf="topCompany && topCompany.isNew">
  <mat-card-header>
    <mat-card-title>
        <div fxLayout="row" fxLayoutAlign="start center">
          <button [routerLink]="['../']" mat-icon-button>
            <mat-icon>arrow_back</mat-icon>
          </button> ¿Desea agregar una nueva empresa?
        </div></mat-card-title>
  </mat-card-header>
  <mat-card-content>
    No hay empresas relacionadas al nit: <strong [innerText]="topCompany.nit"></strong>, puede crear una nueva empresa
    relacionada a este nit o volver a buscar.
  </mat-card-content>
  <mat-card-actions fxLayout="row">
    <div fxFlex></div>
    <a mat-button [routerLink]="[ '/10000-empresas/empresas' ]">Volver a buscar</a>
    <button mat-raised-button color="primary" (click)="topCompany.isNew = false; topCompany.needSave = true;">Crear
      nueva empresa</button>
  </mat-card-actions>
</mat-card>
<div *ngIf="topCompany && !topCompany.isNew" class="cont">
  <!-- <button class="publish-button" mat-fab><mat-icon>save</mat-icon></button> -->
  <eco-fab-speed-dial [(open)]="menuIsOpen" class="publish-button" [direction]="'down'">
    <eco-fab-speed-dial-trigger [spin]="true">
      <button mat-fab [color]="diffDates(topCompany.published, topCompany.modified) > 0 ? 'default' : 'primary'">
        <mat-icon>{{!menuIsOpen ? 'menu': 'close'}}</mat-icon>
      </button>
    </eco-fab-speed-dial-trigger>
    <eco-fab-speed-dial-actions>
      <button mat-mini-fab color="default" [routerLink]="[ '/10000-empresas/empresas' ]">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-mini-fab color="default" [disabled]="diffDates(topCompany.published, topCompany.modified) > 0"
        (click)="publish()">
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <button mat-mini-fab color="default" [disabled]="!(diffDates(topCompany.published, topCompany.modified) > 0)"
        (click)="unpublish()">
        <mat-icon>cloud_off</mat-icon>
      </button>
    </eco-fab-speed-dial-actions>
  </eco-fab-speed-dial>
  <eco-fab-speed-dial [(open)]="menuIsOpen" class="mobile publish-button">
    <eco-fab-speed-dial-trigger [spin]="true">
      <button mat-fab [color]="diffDates(topCompany.published, topCompany.modified) > 0 ? 'default' : 'primary'">
        <mat-icon>{{!menuIsOpen ? 'menu': 'close'}}</mat-icon>
      </button>
    </eco-fab-speed-dial-trigger>
    <eco-fab-speed-dial-actions>
      <button mat-mini-fab color="default" [routerLink]="[ '/10000-empresas/empresas' ]">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-mini-fab color="default" [disabled]="diffDates(topCompany.published, topCompany.modified) > 0"
        (click)="publish()">
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <button mat-mini-fab color="default" [disabled]="!(diffDates(topCompany.published, topCompany.modified) > 0)"
        (click)="unpublish()">
        <mat-icon>cloud_off</mat-icon>
      </button>
    </eco-fab-speed-dial-actions>
  </eco-fab-speed-dial>
  <app-header-top-companies></app-header-top-companies>
  <mat-accordion multi="true">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="topCompany.name"></mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row wrap" fxLayout.xs="column wrap" fxLayoutGap="16px">
        <mat-form-field fxFlex="calc(33% - 25px)">
          <input type="text" matInput placeholder="Nit" [(ngModel)]="topCompany.nit" [disabled]="true" />
          <mat-hint>Nit de la empresa</mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="calc(33% - 25px)">
          <input type="text" matInput placeholder="Nombre de la empresa" [ngModel]="topCompany.name" (ngModelChange)="nameChange($event)" />
          <mat-hint>Ingrese la razón social de la empresa</mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="calc(33% - 25px)">
          <mat-select [ngModel]="topCompany.state" placeholder="Departamento" (ngModelChange)="stateChange($event)">
            <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
          </mat-select>
          <mat-hint>Seleccione la ciudad de la empresa</mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="calc(100% - 25px)">
          <mat-chip-list #chipListAlias>
            <mat-chip *ngFor="let item of topCompany.alias" [selectable]="true"
                      [removable]="true" (removed)="removeAlias(item)">
              {{item}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Alias"
                    [matChipInputFor]="chipListAlias"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="addAlias($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field fxFlex="calc(100% - 25px)">
          <mat-chip-list #chipListTags>
            <mat-chip *ngFor="let item of topCompany.tags" [selectable]="true"
                      [removable]="true" (removed)="removeTags(item)">
              {{item}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Tags"
                   [matChipInputFor]="chipListTags"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addTags($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field fxFlex="calc(100% - 25px)">
          <mat-chip-list #clEconomic>
            <mat-chip *ngFor="let economicActivity of topCompany.economicActivities;let idx=index;" [selectable]="true"
              [removable]="true" (removed)="removeEconomicActivity(idx)">{{economicActivity.id}}-{{economicActivity.title}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Actividades económicas" #eActivitiesInput [formControl]="economicActivitiesACControl"
              [matAutocomplete]="auto" [matChipInputFor]="clEconomic" [matChipInputSeparatorKeyCodes]="[]"
              [matChipInputAddOnBlur]="false" />
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="economicActivitySelected($event)">
            <mat-option *ngFor="let economicActivity of (filteredEconomicActivities | async)" [value]="economicActivity">{{economicActivity.title}}</mat-option>
          </mat-autocomplete>
          <mat-hint>Seleccione las actividades económicas de la empresa (Primero la actividad económica principal)</mat-hint>
        </mat-form-field>
        <mat-form-field fxFlex="calc(100% - 25px)">
          <mat-chip-list #clSectors>
            <mat-chip *ngFor="let sector of topCompany.sectors;let idx=index;" [selectable]="true" [removable]="true"
              (removed)="removeSector(idx)">{{sector}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input placeholder="Sectores" #sectorsInput [formControl]="sectorsACControl" [matAutocomplete]="autoSectors"
              [matChipInputFor]="clSectors" [matChipInputSeparatorKeyCodes]="[]" [matChipInputAddOnBlur]="false" />
          </mat-chip-list>
          <mat-autocomplete #autoSectors="matAutocomplete" (optionSelected)="sectorSelected($event)">
            <mat-option *ngFor="let sector of (filteredSectors | async)" [value]="sector">{{sector}}</mat-option>
          </mat-autocomplete>
          <mat-hint>Seleccione los sectores (Primero el sector principal)</mat-hint>
        </mat-form-field>
        <div fxFlex="calc(50% - 25px)">
          <div fxFlex></div>
          <img *ngIf="topCompany.logo" [src]="topCompany.logo" alt="Imagen">
          <div fxFlex></div>
        </div>
        <div fxFlex="calc(50% - 25px)">
          <input #imgFileInput hidden type="file" name="file" (change)="fileChange($event)" placeholder="Upload file"
            accept=".jpg,.png">
          <ngx-file-drop (onFileDrop)="dropLogo($event)">
            <ng-template ngx-file-drop-content-tmp>
              <button mat-button (click)="imgFileInput.click()">Subir Imagen</button> o arrastrar aquí
            </ng-template>
          </ngx-file-drop>
        </div>
      </div><br />
      <div fxLayout="row" *ngIf="topCompany.needSave">
        <div fxFlex></div><button mat-button color="primary" (click)="saveCompany()">Guardar</button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" *ngIf="!topCompany.needSave">
      <mat-expansion-panel-header>
        <mat-panel-title>Hoja de datos</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-table class="text-table" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="field">
          <mat-header-cell *matHeaderCellDef> Campo </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status === 'creating'; else elseTemplate">
              <mat-form-field class="input-editor">
                <input type="text" matInput placeholder="Nombre del campo" [(ngModel)]="element.field" />
                <mat-hint>Ingrese el nombre del campo (Recuerde que debe ser único por empresa)</mat-hint>
              </mat-form-field>
            </ng-container>
            <ng-template #elseTemplate>
              {{element.field}}
            </ng-template>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Orden </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-form-field class="input-editor">
                <input type="number" matInput placeholder="Orden" [ngModel]="element.order" (ngModelChange)="updateTextOrderField($event, element)" />
                <mat-hint>Ascendente</mat-hint>
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-form-field class="input-editor">
              <input type="text" matInput placeholder="Valor" [ngModel]="element.value" (ngModelChange)="updateTextValueField($event, element)" />
              <mat-hint>Ingrese el valor</mat-hint>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updatedc">
          <mat-header-cell *matHeaderCellDef> Actualizado</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <ng-container *ngIf="element.saving; else updateElse">
                <div>Guardando</div>
                <mat-spinner [diameter]="30"> </mat-spinner>
              </ng-container>
              <ng-template #updateElse>
                <div [innerText]="element.updated | date: 'MMM dd yyyy'"></div>
                <div [innerText]="element.updatedBy"></div>
              </ng-template>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button mat-icon-button color="primary" (click)="addNewTextField()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let idx = index;">
            <ng-container *ngIf="element.status === 'creating'; else btnElse">
              <ng-container *ngIf="!element.saving">
                <button mat-icon-button (click)="cancelTextField(idx)">
                  <mat-icon color="warn">close</mat-icon>
                </button>
                <button mat-icon-button (click)="saveTextField(element)">
                  <mat-icon color="primary">save</mat-icon>
                </button>
              </ng-container>
            </ng-container>
            <ng-template #btnElse>
              <ng-container *ngIf="element.deleting; else elseTemplate">
                Eliminando <mat-spinner [diameter]="30"> </mat-spinner>
              </ng-container>
              <ng-template #elseTemplate>
                <button mat-icon-button (click)="removeTextField(element, idx)">
                  <mat-icon color="warn">close</mat-icon>
                </button>
              </ng-template>
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="textDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: textDisplayedColumns"></mat-row>
      </mat-table>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" *ngIf="!topCompany.needSave">
      <mat-expansion-panel-header>
        <mat-panel-title>Datos Numéricos</mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div fxFlex></div>
        <button mat-button (click)="addNewYear()">
          <mat-icon>add</mat-icon> Agregar Nuevo Año
        </button>
      </div>
      <mat-tab-group>
        <mat-tab *ngFor="let yearData of topCompany.numericGroups" [label]="yearData.year">
          <mat-table class="numeric-table" [dataSource]="yearData.numericDatasheets">
            <!-- <ng-container matColumnDef="vectorName"> <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell> <mat-cell *matCellDef="let element">{{element.vector.name}}</mat-cell> </ng-container> -->
            <ng-container matColumnDef="value">
              <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="input-editor" fxLayout="row" fxLayoutAlign="center center">
                  <mat-form-field fxFlex="50">
                    <input type="number" matInput placeholder="{{element.vector.name}}" [ngModel]="element.value"
                      (ngModelChange)="updateNumericField($event, element)" />
                    <mat-hint>Valor en <strong [innerText]="element.vector.scale"></strong></mat-hint>
                  </mat-form-field>
                  <div fxFlex>{{element.saving ? "Guardando" : ""}}</div>
                  <div [innerText]="element.value | currency: '$'"></div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated">
              <mat-header-cell *matHeaderCellDef> Actualizado </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="date-string">
                  <div [innerText]="element.updated | date: 'MMM dd yyyy'"></div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updatedBy">
              <mat-header-cell *matHeaderCellDef> Actualizado por</mat-header-cell>
              <mat-cell *matCellDef="let element">{{element.updatedBy}}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="numericDisplayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: numericDisplayedColumns"></mat-row>
          </mat-table>
        </mat-tab>
      </mat-tab-group>
    </mat-expansion-panel>
  </mat-accordion>
</div>

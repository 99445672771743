<div fxLayout="row">
  <div fxFlex></div>
  <img class="logo" src="/assets/images/logo-diezmil-rojo.png" alt="10000 Empresas">
</div>
<mat-toolbar class="custom-toolbar">
  <mat-toolbar-row>
    <button routerLinkActive="active mat-primary" [routerLink]="['/10000-empresas/empresas']" mat-button>EMPRESAS</button>
    <button routerLinkActive="active mat-primary" [routerLink]="['/10000-empresas/tips']" mat-button>TIPS</button>
  </mat-toolbar-row>
</mat-toolbar>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { QuotesRoutingModule } from "./quotes-routing.module";
import { QuoteIndexComponent } from "./components/quote-index/quote-index.component";
import { MaterialModule } from "../material.module";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedModule } from "../shared/shared.module";
import { QuoteInputComponent } from "./components/quote-input/quote-input.component";
import { QuoteAdminComponent } from "./components/quote-admin/quote-admin.component";
import { QuoteAdminIndexComponent } from "./components/quote-admin-index/quote-admin-index.component";
import { BvcinfoAdminIndexComponent } from "./components/bvcinfo-admin-index/bvcinfo-admin-index.component";
import { BvcinfoAdminComponent } from "./components/bvcinfo-admin/bvcinfo-admin.component";
import { CategoryAdminComponent } from "./components/category-admin/category-admin.component";
import { CategoryAdminIndexComponent } from "./components/category-admin-index/category-admin-index.component";
import { IpcAdminIndexComponent } from "./components/ipc-admin-index/ipc-admin-index.component";
import { LoadIpcFileComponent } from "./components/load-ipc-file/load-ipc-file.component";
import { NgxFileDropModule } from "ngx-file-drop";

@NgModule({
  declarations: [
    QuoteIndexComponent,
    QuoteInputComponent,
    QuoteAdminComponent,
    QuoteAdminIndexComponent,
    BvcinfoAdminIndexComponent,
    BvcinfoAdminComponent,
    CategoryAdminComponent,
    CategoryAdminIndexComponent,
    IpcAdminIndexComponent,
    LoadIpcFileComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CommonModule,
    MaterialModule,
    QuotesRoutingModule,
    SharedModule,
    NgxFileDropModule,
  ],
})
export class QuotesModule {}

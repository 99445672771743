<app-header-top-companies></app-header-top-companies>
<mat-card>
  <mat-card-header>
    <mat-card-title>Listado de TIPS</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex class="filter-tip">
        <mat-form-field>
          <input matInput placeholder="Filtro" [formControl]="tipFilter">
          <button mat-button *ngIf="tipFilter.value" matSuffix mat-icon-button aria-label="Clear" (click)="tipFilter.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="full">
      <mat-table [dataSource]="dataSource" matSort matSortActive="id" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="year">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Año </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.year}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Título </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="value">
          <mat-header-cell *matHeaderCellDef> Valor </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.value}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="subvalue">
          <mat-header-cell *matHeaderCellDef> Sub </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subValue}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <div class="row" fxLayout="row" fxLayoutAlign="end center">
              <button [routerLink]="['nuevo']" mat-button>
                <mat-icon>add</mat-icon>
                Nuevo
              </button>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="row" fxLayout="row" fxLayoutAlign="end center">
              <a [routerLink]="[element.id]" mat-icon-button color="primary">
                <mat-icon>edit</mat-icon>
              </a>
              <a (click)="deleteTip(element)" mat-icon-button color="warn">
                <mat-icon>delete</mat-icon>
              </a>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColums"> </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColums;">
        </mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count" [pageSize]="50" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>

import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { map, finalize } from 'rxjs/operators';
import { Tip } from '../models/tip.model';
import { TipService } from '../services/tip.service';

export class TipsDataSource implements DataSource<Tip> {
  private tipsSubject = new BehaviorSubject<Tip[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private tipsService: TipService) { }

  connect(_collectionViewer: CollectionViewer): Observable<Tip[]> {
    return this.tipsSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.tipsSubject.complete();
    this.loadingSubject.complete();
  }

  loadTips(page?: number, limit?: number, sort?: string, topic?: string) {
    this.loadingSubject.next(true);
    this.tipsService.searchTips(page, limit, sort, topic)
      .pipe(
        map(v => {
          this.count = v.count;
          return v.tips;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.tipsSubject.next(v));
  }
}

import { DataSource } from '@angular/cdk/table';
import { Datasheet } from '../models/datasheet.model';
import { CollectionViewer } from '@angular/cdk/collections';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

export class DatasheetDataSource implements DataSource<Datasheet> {
  private datasheetsSubject = new BehaviorSubject<Datasheet[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  connect(_collectionViewer: CollectionViewer): Observable<Datasheet[]> {
    return this.datasheetsSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.datasheetsSubject.complete();
    this.loadingSubject.complete();
  }

  loadDatasheets(data: Datasheet[]) {
    this.datasheetsSubject.next(data);
  }
}

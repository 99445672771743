<form (submit)="saveBVCInfo()" [formGroup]="editBVCInfoForm" fxLayout="column">
  <mat-card>
    <mat-card-header>
      <mat-card-title fxLayout="row" fxLayoutAlign="flex-start center">
        <a fxFlexAlign="center" routerLink="../">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div>Editar acción {{editBVCInfoForm.get('name').value}}</div>
      </mat-card-title>
      <mat-card-subtitle>Modifique los campos relacionados y finalice guardando</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <mat-form-field>
          <mat-chip-list #chipListNits>
            <mat-chip *ngFor="let item of Nits" [selectable]="selectableNit"
                      [removable]="removableNit" (removed)="removeNit(item)">
              {{item}}
              <mat-icon matChipRemove *ngIf="removableNit">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Nits"
                    [matChipInputFor]="chipListNits"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addNitOnBlur"
                    (matChipInputTokenEnd)="addNit($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
          <mat-chip-list #chipListTags>
            <mat-chip *ngFor="let item of Tags" [selectable]="selectableTag"
                      [removable]="removableTag" (removed)="removeTag(item)">
              {{item}}
              <mat-icon matChipRemove *ngIf="removableTag">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Tags"
                    [matChipInputFor]="chipListTags"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addTagOnBlur"
                    (matChipInputTokenEnd)="addTag($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
          <mat-chip-list #chipListAlias>
            <mat-chip *ngFor="let item of alias" [selectable]="selectableTag"
                      [removable]="removableTag" (removed)="removeAlias(item)">
              {{item}}
              <mat-icon matChipRemove *ngIf="removableTag">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Alias"
                    [matChipInputFor]="chipListAlias"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addTagOnBlur"
                    (matChipInputTokenEnd)="addAlias($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field>
          <mat-placeholder>Nota</mat-placeholder>
          <input matInput autocomplete="off" formControlName="notes">
        </mat-form-field>
        <mat-form-field>
          <mat-placeholder>Descripción</mat-placeholder>
          <textarea matInput autocomplete="off" formControlName="description"></textarea>
        </mat-form-field>
        <mat-checkbox formControlName="isIndex">Es indice accionario</mat-checkbox>
        <mat-form-field>
          <mat-placeholder>Posición de header</mat-placeholder>
          <input matInput autocomplete="off" formControlName="headerPosition">
          <mat-hint>Indica la posición en el header, (0) cero se despliega.</mat-hint>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end center">
      <button mat-raised-button color="primary" mat-button>guardar</button>
    </mat-card-actions>
  </mat-card>
</form>


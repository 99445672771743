import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Tip } from '../../models/tip.model';
import { TipService } from '../../services/tip.service';

@Component({
  selector: 'app-edit-tip',
  templateUrl: './edit-tip.component.html',
  styleUrls: ['./edit-tip.component.scss']
})
export class EditTipComponent implements OnInit {
  id: number;
  tip: Tip;
  constructor(private activatedRoute: ActivatedRoute,
    private tipService: TipService) { }
  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.loadTip();
  }


  loadTip() {
    this.tipService.getTip(this.id).subscribe(v => this.tip = v);
  }
}

<form (ngSubmit)="createTip()" #updOrCreateForm="ngForm" [formGroup]="tipGroup">
  <div fxLayout="row wrap" fxLayout.xs="column wrap">
    <mat-form-field fxFlex="34">
      <input type="number" matInput placeholder="Año" formControlName="year" autocomplete="off">
    </mat-form-field>
    <div fxFlex="calc(16px)" fxFlex.xs="0"></div>
    <mat-form-field fxFlex="calc(33% - 16px)">
      <input type="text" matInput placeholder="Título" formControlName="title" autocomplete="off">
    </mat-form-field>
    <div fxFlex="calc(16px)" fxFlex.xs="0"></div>
    <mat-form-field fxFlex="calc(33% - 16px)">
      <input type="text" matInput placeholder="Valor" formControlName="value" autocomplete="off">
    </mat-form-field>
    <mat-form-field fxFlex="50">
      <input type="text" matInput placeholder="Subtítulo" formControlName="subvalue" autocomplete="off">
    </mat-form-field>
    <div fxFlex="calc(16px)" fxFlex.xs="0"></div>
    <mat-form-field fxFlex="calc(50% - 16px)">
      <input type="text" matInput placeholder="Descripción" formControlName="description" autocomplete="off">
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="end end">
    <a [routerLink]="['../']" mat-button>Volver</a>
    <button *ngIf="!tip.id" mat-raised-button color="primary">Crear</button>
    <button *ngIf="tip.id" mat-raised-button color="primary">Guardar</button>
  </div>
</form>

<div fxLayout="row" fxLayoutAlign="center start">
  <div class="preview">
    <div class="title" [innerText]="tipGroup.value.title"></div>
    <div class="value" [innerText]="tipGroup.value.value"></div>
    <div class="subvalue" [innerText]="tipGroup.value.subvalue"></div>
    <div class="description" [innerText]="tipGroup.value.description"></div>
  </div>
</div>

<app-header-top-companies></app-header-top-companies>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button [routerLink]="['../']" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button> Crear Tip
      </div>
    </mat-card-title>
    <mat-card-subtitle>Ingrese los datos solicitados para crear un nuevo TIP</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <app-tip [tip]="tip"></app-tip>
  </mat-card-content>
</mat-card>


import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SubscriberResponse } from '../../models/subscriber-response.model';
import { LRPlusService } from '../../services/lrplus.service';

@Component({
  selector: 'app-subscriber-result',
  templateUrl: './subscriber-result.component.html',
  styleUrls: ['./subscriber-result.component.scss']
})
export class SubscriberResultComponent implements OnInit {
  loadingModule = true;
  id: string;
  subscriberResponse: SubscriberResponse;
  newEmail = new FormControl('', [Validators.email]);
  displayedColums = ['email', 'confirmedEmail', 'actions'];
  loading: boolean;
  constructor(private activatedRoute: ActivatedRoute,
    private lrplusService: LRPlusService,
    private snackBar: MatSnackBar) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getSubscriber();
  }

  getSubscriber() {
    this.loading = true;
    this.lrplusService.searchSubscriber(this.id).subscribe(
      v => this.subscriberResponse = v,
      err => { },
      () => {
        this.loadingModule = false;
        this.loading = false;
      }
    );
  }

  addUser() {
    if (this.newEmail.valid && this.newEmail.touched && !this.loading) {
      this.loading = true;
      const refEmail = this.newEmail.value;
      this.newEmail.reset();
      this.lrplusService.subscriberAddUser(refEmail, this.id).subscribe(
        v => {
          if (!v.success) {
            this.snackBar.open(v.errorDescription, "Cerrar",);
            this.loading = false;
          }
          else {
            this.snackBar.open('Correo agregado correctamente.', null, {
              duration: 2000,
            });
            this.getSubscriber();
          }
        },
        err => this.loading = false
        );
    }
  }

  removeEmail(email: string) {
    if (!this.loading) {
      this.loading = true;
      this.lrplusService.subscriberDeleteUser(email, this.id).subscribe(
        v => {
          if (!v.success) {
            this.snackBar.open(v.errorDescription, "Cerrar",);
          }
          else {
            this.subscriberResponse.confirmedUsers = this.subscriberResponse.confirmedUsers.filter(r => r.email != email);
            this.subscriberResponse.unconfirmedUsers = this.subscriberResponse.unconfirmedUsers.filter(r => r.email != email);
            this.subscriberResponse.relatedUsers--;
            this.snackBar.open('Correo eliminado correctamente.', null, {
              duration: 2000,
            });
          }
        },
        err => { },
        () => {
          this.loading = false;
        });
    }
  }
}

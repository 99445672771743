import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { map, finalize } from 'rxjs/operators';
import { QQuote } from '../models/q-quote.model';
import { QuoteService } from '../services/quote.service';

export class QuotesDataSource implements DataSource<QQuote> {
  private quotesSubject = new BehaviorSubject<QQuote[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private quoteService: QuoteService) { }

  connect(_collectionViewer: CollectionViewer): Observable<QQuote[]> {
    return this.quotesSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.quotesSubject.complete();
    this.loadingSubject.complete();
  }

  loadQuotes(page?: number, pagesize?: number) {
    this.loadingSubject.next(true);
    this.quoteService.getQuotes(page, pagesize)
      .pipe(
        map(v => {
          this.count = v.total;
          return v.quotes;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.quotesSubject.next(v));
  }
}

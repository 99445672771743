import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-top-companies',
  templateUrl: './header-top-companies.component.html',
  styleUrls: ['./header-top-companies.component.scss']
})
export class HeaderTopCompaniesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<form (submit)="saveCategory()" [formGroup]="editCategoryForm" fxLayout="column">
  <mat-card>
    <mat-card-header>
      <mat-card-title fxLayout="row" fxLayoutAlign="flex-start center">
        <a fxFlexAlign="center" routerLink="../">
          <mat-icon>keyboard_backspace</mat-icon>
        </a>
        <div>Editar categoría {{editCategoryForm.get('name').value}}</div>
      </mat-card-title>
      <mat-card-subtitle>Modifique los campos relacionados y finalice guardando</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column">
        <mat-form-field>
          <mat-placeholder>Descripción</mat-placeholder>
          <textarea matInput autocomplete="off" formControlName="description"></textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-placeholder>Peso</mat-placeholder>
          <input matInput autocomplete="off" formControlName="weight">
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end center">
      <button mat-raised-button color="primary" mat-button>guardar</button>
    </mat-card-actions>
  </mat-card>
</form>


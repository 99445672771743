import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuoteService } from '../../services/quote.service';
import { QQuote } from '../../models/q-quote.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-quote-admin',
  templateUrl: './quote-admin.component.html',
  styleUrls: ['./quote-admin.component.scss']
})
export class QuoteAdminComponent implements OnInit {
  id: number;
  submitted: boolean;
  editQuoteForm = this.fb.group({
    id: [null],
    name: [null],
    format: [null],
    currency: [null],
    frecuency: [null],
    graphicType: [null],
    scale: [null],
    notes: [null],
    source: [null],
    sourceUrl: [null],
    tags: [null],
    alias: [null],
    categoryId: [null],
    description: [null],
    isPrincipal: [null],
    weight: [0, Validators.required],
    labelDate: [null],
    headerPosition: [0]
  });
  Tags: string[];
  alias: string[];
  selectableTag = true;
  removableTag = true;
  addTagOnBlur = true;
  readonly separatorKeysCodesTag: number[] = [ENTER, COMMA];
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.getQuote();
  }

  getQuote() {
    this.quoteService.getQuote(this.id).subscribe(v => {
      this.editQuoteForm.patchValue(v);
      this.Tags = this.editQuoteForm.get('tags').value;
      if (!this.Tags) {
        this.Tags = [];
      }
      this.alias = this.editQuoteForm.get('alias').value;
      if (!this.alias) {
        this.alias = [];
      }
    });
  }
  saveQuote() {
    this.submitted = true;
    if (this.editQuoteForm.valid) {
      this.quoteService
        .saveQuote(this.editQuoteForm.value)
        .subscribe(v => {
          if (v.success) {
            this.snackBar.open('Ha actualizado el indicador satisfactoriamente', 'Listo');
          }
        });
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.Tags.push(value.trim());
      this.editQuoteForm.get('tags').setValue(this.Tags);
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(intItem: string): void {
    const index = this.Tags.indexOf(intItem);

    if (index >= 0) {
      this.Tags.splice(index, 1);
      this.editQuoteForm.get('tags').setValue(this.Tags);
    }
  }


  addAlias(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.alias.push(value.trim());
      this.editQuoteForm.get('alias').setValue(this.alias);
    }

    if (input) {
      input.value = '';
    }
  }

  removeAlias(intItem: string): void {
    const index = this.alias.indexOf(intItem);

    if (index >= 0) {
      this.alias.splice(index, 1);
      this.editQuoteForm.get('alias').setValue(this.alias);
    }
  }
}

import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { QuoteService } from '../../services/quote.service';
import { BVCInfoDataSource } from '../../datasources/bvcinfo-data-source';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-bvcinfo-admin-index',
  templateUrl: './bvcinfo-admin-index.component.html',
  styleUrls: ['./bvcinfo-admin-index.component.scss']
})
export class BvcinfoAdminIndexComponent implements OnInit, AfterViewInit {
  page: number;
  pageSize: number;
  dataSource: BVCInfoDataSource;
  displayedColums = ['name', 'notes', 'description', 'actions' ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(quoteService: QuoteService) {
    this.dataSource = new BVCInfoDataSource(quoteService);
  }

  ngOnInit() {
    this.dataSource.loadBVCInfo(1, 10);
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getBVCInfo())).subscribe();
  }

  getBVCInfo() {
    this.dataSource.loadBVCInfo(this.paginator.pageIndex + 1, this.paginator.pageSize);
  }

}

import { Component, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';

export const MONTH_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monthpicker',
  templateUrl: './monthpicker.component.html',
  styleUrls: ['./monthpicker.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
  ]
})
export class MonthpickerComponent {

  @Output() changed = new EventEmitter<Moment>();
  monthCtrl: FormControl;

  constructor() {
    const actualMonth = moment();
    actualMonth.date(1);
    this.monthCtrl = new FormControl(actualMonth);
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.monthCtrl.value;
    ctrlValue.year(normalizedYear.year());
    this.monthCtrl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.monthCtrl.value;
    ctrlValue.month(normalizedMonth.month());
    ctrlValue.date(1);
    this.monthCtrl.setValue(ctrlValue);
    this.changed.emit(ctrlValue);
    datepicker.close();
  }
}

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { QQuoteValue } from "../../models/q-quote-value.model";
import { FormControl, Validators } from "@angular/forms";
import {
  debounce,
  debounceTime,
  startWith,
  distinctUntilChanged,
  switchMap,
  filter,
} from "rxjs/operators";
import { Observable } from "rxjs";
import { QuoteService } from "../../services/quote.service";

@Component({
  selector: "app-quote-input",
  templateUrl: "./quote-input.component.html",
  styleUrls: ["./quote-input.component.scss"],
})
export class QuoteInputComponent implements OnInit {
  private _cquote: QQuoteValue;
  private _cquotevalue: number;
  private emitEvent = false;

  @Input() get isPercent(): boolean {
    if (this.cQuote && this.cQuote.quote.format.startsWith("P")) {
      return true;
    }
    return false;
  }

  @Input()
  set cQuoteValue(cquotevalue: number) {
    this._cquotevalue = cquotevalue;
    this.formCtrl.setValue(this._cquotevalue, {
      emitEvent: this.emitEvent,
    });
    this.emitEvent = true;
  }
  get cQuoteValue(): number {
    return this._cquotevalue;
  }

  @Input()
  set cQuote(cquote: QQuoteValue) {
    this._cquote = cquote;
  }
  get cQuote(): QQuoteValue {
    return this._cquote;
  }

  saving = false;
  saved = false;
  formCtrl: FormControl;
  @ViewChild("rrr", { static: true }) rrr: ElementRef;
  constructor(private quoteService: QuoteService) {
    this.formCtrl = new FormControl(this._cquotevalue, [
      Validators.min(-100000000),
      Validators.max(100000000),
    ]);
  }

  ngOnInit(): void {
    this.formCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter(() => this.rrr.nativeElement.validity.valid),
        switchMap((val) => {
          this.saving = true;
          this.cQuote.actualValue.value = val;
          if (!this.cQuote.actualValue.format) {
            this.cQuote.actualValue.format = this.cQuote.quote.format;
          }
          return this.quoteService.saveQuoteValue(
            this.cQuote.quote.id,
            this.cQuote.actualValue
          );
        })
      )
      .subscribe(() => {
        this.saving = false;
        this.saved = true;
        setTimeout(() => {
          this.saved = false;
        }, 5000);
      });
  }

  cleanFormControl() {
    this.cQuote.actualValue.value = null;
  }
}

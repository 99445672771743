import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { map, finalize } from 'rxjs/operators';
import { Pqr } from '../models/pqr.model';
import { PqrService } from '../services/pqr.service';

export class PqrDataSource implements DataSource<Pqr> {
  private pqrsSubject = new BehaviorSubject<Pqr[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private pqrService: PqrService) { }

  connect(_collectionViewer: CollectionViewer): Observable<Pqr[]> {
    return this.pqrsSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.pqrsSubject.complete();
    this.loadingSubject.complete();
  }

  loadPqrs(page?: number, limit?: number, status?: string) {
    this.loadingSubject.next(true);
    this.pqrService.getPqrs(page, limit, status)
      .pipe(
        map(v => {
          this.count = v.total;
          return v.pqRs;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.pqrsSubject.next(v));
  }
}

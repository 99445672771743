import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from 'src/app/services/user.service';
import { merge } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loading = true;
  email: string;
  constructor(private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.userService.getUser().subscribe(v => {
      if (v) {
        this.userService.user = v;
        this.userService.isLoggedIn = true;
        const redirect = this.userService.redirectUrl
          ? this.userService.redirectUrl
          : '/';
        this.loading = false;
        this.router.navigate([redirect]);
      }
      if (true) {
        activatedRoute.url.pipe(
          map(r => r[0].path === 'sin-acceso'),
          filter(fr => fr),
          switchMap(() => activatedRoute.queryParamMap)
        )
        .subscribe(q => {
          debugger;
          this.email = q.get('email');
        });
      }
      this.loading = false;
    });
  }

}

import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { QuoteService } from '../../services/quote.service';
import { MatPaginator } from '@angular/material/paginator';
import { QuotesDataSource } from '../../datasources/quotes-data-source';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-quote-admin-index',
  templateUrl: './quote-admin-index.component.html',
  styleUrls: ['./quote-admin-index.component.scss']
})
export class QuoteAdminIndexComponent implements OnInit, AfterViewInit {

  page: number;
  pageSize: number;
  dataSource: QuotesDataSource;
  displayedColums = ['name', 'format', 'currency', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(quoteService: QuoteService) {
    this.dataSource = new QuotesDataSource(quoteService);
  }

  ngOnInit() {
    this.dataSource.loadQuotes(1, 10);
  }
  ngAfterViewInit() {
    this.paginator.page.pipe(tap(() => this.getQuotes())).subscribe();
  }

  getQuotes() {
    this.dataSource.loadQuotes(this.paginator.pageIndex + 1, this.paginator.pageSize);
  }
}

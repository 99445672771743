<mat-card *ngIf="!submitted">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button [routerLink]="['../']" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <h2>Crear PQR</h2>
    <form (submit)="addPqr()" [formGroup]="addPqrForm">
      <mat-form-field>
        <mat-label>Tipo Documento Cliente</mat-label>
        <mat-select placeholder="Seleccione" formControlName="clientTid" required>
          <mat-option *ngFor="let docType of docTypes" [value]="docType">
            {{docType}}
          </mat-option>
        </mat-select>
        <mat-error>El tipo de documento es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>No. Documento Cliente</mat-label>
        <input matInput formControlName="clientId" autocomplete="off" required>
        <mat-error>El número de documento es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Nombre Cliente</mat-label>
        <input matInput formControlName="clientName" autocomplete="off" required>
        <mat-error>El nombre es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email Cliente</mat-label>
        <input matInput formControlName="clientEmail" autocomplete="off">
        <mat-error>El email es incorrecto</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="clientMobile" autocomplete="off">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Origen</mat-label>
        <mat-select placeholder="Seleccione" formControlName="source" required>
          <mat-option *ngFor="let item of sources" [value]="item">
            {{item}}
          </mat-option>
        </mat-select>
        <mat-error>El origen es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Asunto</mat-label>
        <input matInput formControlName="subject" autocomplete="off" required>
        <mat-error>El asunto es obligatorio</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Descripción</mat-label>
        <textarea matInput matTextareaAutosize matAutosizeMinRows="1" formControlName="description" required></textarea>
        <mat-error>La descripción es obligatoria</mat-error>
      </mat-form-field>
      <div class="submit-button">
        <button mat-raised-button color="primary">Guardar</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="submitted && id">
  <mat-card-header>
    <mat-card-title>PQR Guardada</mat-card-title>
  </mat-card-header>
  <mat-card-content class="text-center">
    PQR Guardada de forma satisfactoria con radicado <strong>{{id}}</strong>
  </mat-card-content>
  <mat-card-actions>
    <button [routerLink]="['../']" mat-button>Ok</button>
  </mat-card-actions>
</mat-card>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthpickerComponent } from './components/monthpicker/monthpicker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { YearpickerComponent } from './components/yearpicker/yearpicker.component';
import { QuarterpickerComponent } from './components/quarterpicker/quarterpicker.component';
import { RangepickerComponent } from './components/rangepicker/rangepicker.component';

@NgModule({
  declarations: [MonthpickerComponent, YearpickerComponent, QuarterpickerComponent, RangepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    MonthpickerComponent,
    YearpickerComponent,
    QuarterpickerComponent,
    RangepickerComponent
  ]
})
export class SharedModule { }

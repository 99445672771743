<app-header-top-companies></app-header-top-companies>
<mat-accordion [multi]="true">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      Buscar Empresa
    </mat-expansion-panel-header>
    <form (ngSubmit)="doSearch()" #searchForm="ngForm">
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Número de identificación tributaria (NIT)" [formControl]="numberFormControl"
            [errorStateMatcher]="matcher">
          <mat-hint>Ingrese el número sin dígito de verificación</mat-hint>
          <mat-error *ngIf="numberFormControl.errors && numberFormControl.errors.pattern">
            Por favor ingrese un número sin puntos ni letras
          </mat-error>
          <!-- <mat-error *ngIf="numberFormControl.hasError('pattern') && !emailFormControl.hasError('required')">
            Por favor ingrese un número
          </mat-error> -->
          <mat-error *ngIf="numberFormControl.hasError('required')">
            El número de identificación es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <div fxFlex></div>
        <button mat-button color="primary" [disabled]="!searchForm.form.valid">Buscar</button>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      Listado
    </mat-expansion-panel-header>
    <div *ngIf="dataSource.loading$ | async">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxFlex class="filter-company">
        <mat-form-field>
          <input matInput placeholder="Filtro" [formControl]="companyFilter">
          <button mat-button *ngIf="companyFilter.value" matSuffix mat-icon-button aria-label="Clear" (click)="companyFilter.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="full">
      <mat-table [dataSource]="dataSource" matSort matSortActive="nit" matSortDirection="asc" matSortDisableClear>
        <ng-container matColumnDef="nit">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nit </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.nit}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Razón social </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef> Departamento </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a [routerLink]="[element.nit]" mat-icon-button color="primary">
              <mat-icon>edit</mat-icon>
            </a></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColums"> </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColums;">
        </mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count" [pageSize]="50" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>

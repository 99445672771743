import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { QuoteService } from '../../services/quote.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-bvcinfo-admin',
  templateUrl: './bvcinfo-admin.component.html',
  styleUrls: ['./bvcinfo-admin.component.scss']
})
export class BvcinfoAdminComponent implements OnInit {
  name: string;
  submitted: boolean;
  editBVCInfoForm = this.fb.group({
    name: [null],
    nit: [null],
    tags: [null],
    alias: [null],
    notes: [null],
    description: [null],
    isIndex: [null],
    headerPosition: [0]
  });
  Nits: string[];
  selectableNit = true;
  removableNit = true;
  addNitOnBlur = true;
  Tags: string[];
  alias: string[];
  selectableTag = true;
  removableTag = true;
  addTagOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private quoteService: QuoteService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.name = this.activatedRoute.snapshot.paramMap.get('name');
    this.getBVCInfo();
  }

  getBVCInfo() {
    this.quoteService.getBVCInfoById(this.name).subscribe(v => {
        this.editBVCInfoForm.patchValue(v);
        this.Nits = this.editBVCInfoForm.get('nit').value;
        if (!this.Nits) {
          this.Nits = [];
        }
        this.Tags = this.editBVCInfoForm.get('tags').value;
        if (!this.Tags) {
          this.Tags = [];
        }
        this.alias = this.editBVCInfoForm.get('alias').value;
        if (!this.alias) {
          this.alias = [];
        }
      });
  }

  saveBVCInfo() {
    this.submitted = true;
    if (this.editBVCInfoForm.valid) {
      this.quoteService
        .saveBVCInfo(this.editBVCInfoForm.value)
        .subscribe(v => {
          if (v.success) {
            this.snackBar.open('Ha actualizado la accion satisfactoriamente', 'Listo');
          }
        });
    }
  }

  addNit(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.Nits.push(value.trim());
      this.editBVCInfoForm.get('nit').setValue(this.Nits);
    }

    if (input) {
      input.value = '';
    }
  }

  removeNit(intItem: string): void {
    const index = this.Nits.indexOf(intItem);

    if (index >= 0) {
      this.Nits.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.Tags.push(value.trim());
      this.editBVCInfoForm.get('tags').setValue(this.Tags);
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(intItem: string): void {
    const index = this.Tags.indexOf(intItem);

    if (index >= 0) {
      this.Tags.splice(index, 1);
      this.editBVCInfoForm.get('tags').setValue(this.Tags);
    }
  }

  addAlias(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.alias.push(value.trim());
      this.editBVCInfoForm.get('alias').setValue(this.alias);
    }

    if (input) {
      input.value = '';
    }
  }

  removeAlias(intItem: string): void {
    const index = this.alias.indexOf(intItem);

    if (index >= 0) {
      this.alias.splice(index, 1);
      this.editBVCInfoForm.get('alias').setValue(this.alias);
    }
  }

}

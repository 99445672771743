import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsCoExtra from '@angular/common/locales/extra/es-CO';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TopCompaniesModule } from './modules/top-companies.module';
import { MaterialModule } from './modules/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';

import { AuthGuardService } from './services/auth-guard.service';
import { BaseService } from './services/base.service';
import { UserService } from './services/user.service';

import { HomeIndexComponent } from './components/home/home-index/home-index.component';
import { LoginComponent } from './components/home/login/login.component';
import { AppComponent } from './app.component';
import { LRPlusModule } from './modules/lrplus.module';
import { NotificationsModule } from './modules/notifications.module';
import { QuotesModule } from './modules/quotes/quotes.module';
import { PqrModule } from './modules/pqr.module';

registerLocaleData(localeEsCo, 'es-CO', localeEsCoExtra);

@NgModule({
  declarations: [
    AppComponent,
    HomeIndexComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TopCompaniesModule,
    LRPlusModule,
    NotificationsModule,
    QuotesModule,
    PqrModule,
    MaterialModule,
    AppRoutingModule
  ],
  providers: [
    AuthGuardService,
    BaseService,
    UserService,
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CO' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

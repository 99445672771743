<app-header-top-companies></app-header-top-companies>
<mat-card *ngIf="tip">
  <mat-card-header>
    <mat-card-title>
      <div fxLayout="row" fxLayoutAlign="start center">
        <button [routerLink]="['../']" mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button> Editar Tip
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-tip [tip]="tip"></app-tip>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="!tip">
  <mat-card-header>
    <mat-card-title>Página no encontrada</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    No hemos encontrado la página solicitada
  </mat-card-content>
</mat-card>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './services/auth-guard.service';

import { LoginComponent } from './components/home/login/login.component';
import { AppComponent } from './app.component';


const routes: Routes = [
  { path: 'empresas-rues', loadChildren: () => import('./modules/rues-company.module').then(m => m.RuesCompanyModule) },
  { path: 'precios-agro', loadChildren: () => import('./modules/agro-prices.module').then(m => m.AgroPricesModule) },
  { path: 'sin-acceso', component: LoginComponent },
  { path: 'ingresar', component: LoginComponent },
  { path: '', component: AppComponent, canActivate: [AuthGuardService]},
  // path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

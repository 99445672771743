import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsCoExtra from '@angular/common/locales/extra/es-CO';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { LRPlusRoutingModule } from './lrplus-routing.module';
import { MaterialModule } from './material.module';
import { IndexComponent } from './lrplus/components/index/index.component';
import { HeaderLrPlusComponent } from './lrplus/components/shared/header-lr-plus/header-lr-plus.component';
import { UserResultComponent } from './lrplus/components/user-result/user-result.component';
import { SubscriberResultComponent } from './lrplus/components/subscriber-result/subscriber-result.component';
import { ReportsComponent } from './lrplus/components/reports/reports.component';


registerLocaleData(localeEsCo, 'es-CO', localeEsCoExtra);
@NgModule({
  declarations: [IndexComponent, HeaderLrPlusComponent, UserResultComponent, SubscriberResultComponent, ReportsComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    LRPlusRoutingModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CO' }
  ]
})
export class LRPlusModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { Observable } from 'rxjs';
import { UserResponse } from '../models/user-response.model';
import { catchError } from 'rxjs/operators';
import { SubscriberResponse } from '../models/subscriber-response.model';
import { UserNotification } from '../models/user-notification.model';
import { UsersCountersResponse } from '../models/users-counters-response';

@Injectable({
  providedIn: 'root'
})
export class LRPlusService {

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService) { }

  getUsersCounters(): Observable<UsersCountersResponse> {
    const url = `${this.baseService.hostDomain}/api/lrplus/user/counters`;
    return this.httpClient.get<UsersCountersResponse>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo contadores de usuarios.');
        return [];
      })
    );
  }

  searchUser(email: string): Observable<UserResponse> {
    const url = `${this.baseService.hostDomain}/api/lrplus/user/${email}`;
    return this.httpClient.get<UserResponse>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo Usuario.');
        return [];
      })
    );
  }

  searchSubscriber(id: string): Observable<SubscriberResponse> {
    const url = `${this.baseService.hostDomain}/api/lrplus/subscriber/${id}`;
    return this.httpClient.get<SubscriberResponse>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo suscriptor.');
        return [];
      })
    );
  }

  subscriberAddUser(email: string, subscriberId: string): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/lrplus/subscriber/add-user`;
    return this.httpClient.post<any>(url, {
      email: email,
      subscriberId: subscriberId.toString()
    }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Agregando usuario a suscripción.');
        return [];
      })
    );
  }

  subscriberDeleteUser(email: string, subscriberId: string): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/lrplus/subscriber/delete-user`;
    return this.httpClient.post<any>(url, {
      email: email,
      subscriberId: subscriberId.toString()
    }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Eliminando usuario de suscripción.');
        return [];
      })
    );
  }
  updateNotification(notif: UserNotification): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/lrplus/user/notification`;
    return this.httpClient.post<any>(url, notif).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando notificación.');
        return [];
      })
    );
  }
  downloadSubscribers() {
    window.location.href = `${this.baseService.hostDomain}/api/lrplus/info/download-subscribers`;
  }
  downloadNonSubscribers(full: boolean) {
    window.location.href = `${this.baseService.hostDomain}/api/lrplus/info/download-non-unsubscribed?full=${full}`;
  }
}

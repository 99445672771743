import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

import { map, finalize } from 'rxjs/operators';
import { QuoteService } from '../services/quote.service';
import { QCategory } from '../models/q-category.model';

export class CategoryDataSource implements DataSource<QCategory> {
  private categorySubject = new BehaviorSubject<QCategory[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  count: number;
  constructor(private quoteService: QuoteService) { }

  connect(_collectionViewer: CollectionViewer): Observable<QCategory[]> {
    return this.categorySubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.categorySubject.complete();
    this.loadingSubject.complete();
  }

  loadCategory() {
    this.loadingSubject.next(true);
    this.quoteService.getCategories()
      .pipe(
        map(v => {
          this.count = v.length;
          return v;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(v => this.categorySubject.next(v));
  }
}

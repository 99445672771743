<div class="custom-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Buscar Noticias</mat-card-title>
    </mat-card-header>
    <mat-card-content>
            <p>Búsque y seleccione la noticia que desea enviar, como notificación de última hora</p>
      <mat-form-field>
        <input matInput placeholder="Escribir noticia" [formControl]="topic" autocomplete="off">
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <div>
    <mat-nav-list class="resultNotice">
      <h3 mat-subheader>Resultados</h3>
      <mat-list-item (click)="openConfirm(item)" *ngFor="let item of acResponse | async">
        <h4  mat-line [innerHTML]="item.title"></h4>
        <mat-icon>send</mat-icon>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>

import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable } from "rxjs";
import { CollectionViewer } from "@angular/cdk/collections";
import { map, finalize } from "rxjs/operators";
import { IpcFile } from "../models/ipc-file.model";
import { IpcService } from "../services/ipc.service";

export class IpcFilesDataSource implements DataSource<IpcFile> {
  private ipcFileSubject = new BehaviorSubject<IpcFile[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();
  total: number;
  constructor(private ipcService: IpcService) {}

  connect(_collectionViewer: CollectionViewer): Observable<IpcFile[]> {
    return this.ipcFileSubject.asObservable();
  }
  disconnect(_collectionViewer: CollectionViewer): void {
    this.ipcFileSubject.complete();
    this.loadingSubject.complete();
  }

  getFiles(page?: number, pagesize?: number) {
    this.loadingSubject.next(true);
    const take: number = pagesize;
    const offset: number = (page - 1) * pagesize;
    this.ipcService
      .getFiles(take, offset)
      .pipe(
        map((v) => {
          this.total = v.total;
          return v.ipcFiles;
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((v) => this.ipcFileSubject.next(v));
  }
}

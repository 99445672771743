import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/services/base.service';
import { ListPqrs } from '../models/list-pqrs.model';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pqr } from '../models/pqr.model';
import { Message } from '../models/message.model';
import { AddMessageRequest } from '../models/add-message-request.model';
import { AddPqrResponse } from '../models/add-pqr-response.model';

@Injectable({
  providedIn: 'root'
})
export class PqrService {

  constructor(
    private httpClient: HttpClient,
    private baseService: BaseService) { }

  statuses = ['Recibida', 'En proceso', 'Finalizada'];
  docTypes = [
    'Cédula de Ciudadanía',
    'Pasaporte',
    'Cédula de Extranjería',
    'Permiso Especial de Permanencia',
    'NIT',
  ];
  sources = ['Contacto Telefónico', 'Correo Electrónico', 'Otros'];

  getPqrs(page?: number, limit?: number, status?: string): Observable<ListPqrs> {
    const url = `${this.baseService.hostDomain}/api/pqr/list`;
    const data: any = {};
    if (page) {
      data.offset = (page - 1) * limit;
    }
    if (limit) {
      data.limit = limit;
    }
    if (status) {
      data.status = status;
    }
    return this.httpClient.get<ListPqrs>(url, { params: data }).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo PQRS.');
        return [];
      })
    );
  }

  getPqr(id: string): Observable<Pqr> {
    const url = `${this.baseService.hostDomain}/api/pqr/id/${id}`;
    return this.httpClient.get<Pqr>(url).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Obteniendo PQR.');
        return [];
      })
    );
  }

  addPqr(pqr: Pqr): Observable<AddPqrResponse> {
    const url = `${this.baseService.hostDomain}/api/pqr/add-pqr`;
    return this.httpClient.post<AddPqrResponse>(url, pqr).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando PQR.');
        return [];
      })
    );
  }

  addMessage(message: AddMessageRequest): Observable<any> {
    const url = `${this.baseService.hostDomain}/api/pqr/add-message`;
    return this.httpClient.post<any>(url, message).pipe(
      catchError(err => {
        this.baseService.handleError(err, 'Guardando respuesta PQR.');
        return [];
      })
    );
  }
}

<mat-accordion [multi]="true">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      Búsqueda
    </mat-expansion-panel-header>
    <form (ngSubmit)="doSearch()" #searchForm="ngForm">
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <input matInput placeholder="Id Radicado" [formControl]="idFormControl" [errorStateMatcher]="matcher">
          <mat-hint>Ingrese el id del radicado</mat-hint>
          <mat-error *ngIf="idFormControl.hasError('required')">
            El Id de radicado es <strong>requerido</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <div fxFlex></div>
        <button mat-button color="primary" [disabled]="!searchForm.form.valid">Buscar</button>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      Listado
    </mat-expansion-panel-header>
    <div *ngIf="dataSource.loading$ | async">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div fxLayout="row">
      <button mat-raised-button color="primary" routerLink="crear">Crear Nuevo PQR</button>
      <div fxFlex></div>
      <div class="filter-company">
        <mat-form-field>
          <mat-select placeholder="Filtro" [formControl]="statusFilter">
            <mat-option *ngFor="let status of statuses" [value]="status">
              {{status}}
            </mat-option>
          </mat-select>
          <button mat-button *ngIf="statusFilter.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="statusFilter.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="full">
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Id Radicado </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="create">
          <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.create | date:'short'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="clientName">
          <mat-header-cell *matHeaderCellDef> Nombre Cliente </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.clientName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="subject">
          <mat-header-cell *matHeaderCellDef> Asunto </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subject}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColums"> </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColums;" [routerLink]="[row.id]">
        </mat-row>
      </mat-table>
    </div>
    <mat-paginator [length]="dataSource.count" [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 100]"></mat-paginator>
  </mat-expansion-panel>
</mat-accordion>

import { Component, OnInit } from '@angular/core';
import { PushServiceService } from '../../services/push-service.service';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged, debounceTime, map, switchMap } from 'rxjs/operators';
import { AutocompleteResponse } from '../../models/autocomplete-response.model';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConfirmSendComponent } from '../confirm-send/confirm-send.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  topic: FormControl;
  acResponse: Observable<AutocompleteResponse[]>;
  constructor(pushService: PushServiceService,
    private confirmBottomSheet: MatBottomSheet) {
    this.topic = new FormControl();
    this.acResponse = this.topic.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(val => {
        if (val) {
          return pushService.autocomplete(val);
        } else {
          return [];
        }
      })
    );
  }

  ngOnInit() {
  }

  openConfirm(post: AutocompleteResponse) {
    this.confirmBottomSheet.open(ConfirmSendComponent, {
      data: post,
      panelClass: 'btsend'
    });
  }

}

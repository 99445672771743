import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './lrplus/components/index/index.component';
import { AuthGuardService } from '../services/auth-guard.service';
import { UserResultComponent } from './lrplus/components/user-result/user-result.component';
import { SubscriberResultComponent } from './lrplus/components/subscriber-result/subscriber-result.component';
import { ReportsComponent } from './lrplus/components/reports/reports.component';

const routes: Routes = [
  { path: 'la-republica-mas/informes', component: ReportsComponent, canActivate: [AuthGuardService] },
  { path: 'la-republica-mas/suscriptor/:id', component: SubscriberResultComponent, canActivate: [AuthGuardService] },
  { path: 'la-republica-mas/usuario/:email', component: UserResultComponent, canActivate: [AuthGuardService] },
  { path: 'la-republica-mas', component: IndexComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LRPlusRoutingModule { }

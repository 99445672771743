import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PqrRoutingModule } from './pqr-routing.module';
import { PqrIndexComponent } from './pqr/components/pqr-index/pqr-index.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PqrDetailComponent } from './pqr/components/pqr-detail/pqr-detail.component';
import { PqrCreateComponent } from './pqr/components/pqr-create/pqr-create.component';

@NgModule({
  declarations: [PqrIndexComponent, PqrDetailComponent, PqrCreateComponent],
  imports: [
    CommonModule,
    PqrRoutingModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule
  ]
})
export class PqrModule { }
